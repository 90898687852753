const keyName = 'oaoih8as0d9f7d6f5dsd90';
export const getToken = () => {
  return window.localStorage.getItem(keyName);
};
export const setToken = (token: string) => {
  window.localStorage.setItem(keyName, token);
};
export const removeToken = () => {
  window.localStorage.removeItem(keyName);
};
