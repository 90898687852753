import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import errorExtractor from '../utils/errorExtractor';
import Http from './../helpers/http';
import { notficationType } from '../models/notfication';
import type Pagination from './../models/pagination';

type notficationState = {
  notficationList: {
    loading: boolean;
    data: notficationType[];
    error?: any;
    pagination?: Pagination;
  };
  notfication: {
    loading: boolean;
    data?: notficationType;
    error?: any;
  };
};

const initialState: notficationState = {
  notficationList: {
    loading: false,
    data: [],
  },
  notfication: {
    loading: false,
  },
};

const notficationSlice = createSlice({
  name: 'notfication',
  initialState,
  reducers: {
    fetchNotficationListRequest: (state) => {
      state.notficationList.loading = true;
    },
    fetchNotficationListSuccess: (state, action) => {
      state.notficationList = {
        loading: false,
        data: action.payload.data,
        pagination: action.payload.meta,
      };
    },
    fetchNotficationListFailure: (state, action) => {
      state.notficationList.loading = false;
      state.notficationList.error = action.payload;
    },
    fetchNotficationRequest: (state) => {
      state.notfication.loading = true;
    },
    fetchNotficationSuccess: (state, action) => {
      state.notfication = {
        loading: false,
        data: action.payload,
      };
    },
    fetchNotficationFailure: (state, action) => {
      state.notfication.loading = false;
      state.notfication.error = action.payload;
    },
    createNotficationSuccess: (state, action) => {
      if (state.notficationList.data.length) {
        state.notficationList.data.push(action.payload);
      }
    },
    updateNotficationSuccess: (state, action) => {
      state.notficationList.data = state.notficationList.data.map(
        (notfication) =>
          notfication.id === action.payload.id ? action.payload : notfication
      );
    },
    deleteNotficationSuccess: (state, action) => {
      state.notficationList.data = state.notficationList.data.filter(
        (notfication) => notfication.id !== action.payload
      );
    },
  },
});

export default notficationSlice.reducer;

export const notficationActions = notficationSlice.actions;

//OTHER ACTIONS
export const fetchAllNotficationAction =
  (pageNo: number) => async (dispatch: any) => {
    dispatch(notficationActions.fetchNotficationListRequest());
    try {
      const response = await Http.Get({
        path: `notification${pageNo ? `?page=${pageNo}` : ''}`,
        useAuth: true,
      });
      const result = await response.json();

      if (!response.ok) {
        throw new Error('Failed');
      }
      dispatch(notficationActions.fetchNotficationListSuccess(result));
    } catch (error: any) {
      dispatch(notficationActions.fetchNotficationListFailure(error.message));
    }
  };

export const fetchNotficationAction =
  (notficationId: number) => async (dispatch: any) => {
    dispatch(notficationActions.fetchNotficationRequest());
    try {
      const response = await Http.Get({
        path: `notification/${notficationId}`,
        useAuth: true,
      });
      const result = await response.json();

      if (!response.ok) {
        throw new Error('Failed');
      }
      dispatch(notficationActions.fetchNotficationSuccess(result));
    } catch (error: any) {
      dispatch(notficationActions.fetchNotficationFailure(error.message));
    }
  };

export const createNotficationAction = (data: any) => async (dispatch: any) => {
  try {
    const response = await Http.Post({
      path: `notification`,
      data,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }

    dispatch(notficationActions.createNotficationSuccess(result));
    toast.success('Created');
  } catch (error: any) {
    toast.error(error.message);
    throw error;
  }
};
export const resentNotficationAction =
  (id: number, data: any) => async (dispatch: any) => {
    try {
      const response = await Http.Put({
        path: `notification/${id}`,
        data,
        useAuth: true,
      });
      const result = await response.json();

      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }

      toast.success('Updated');
      dispatch(notficationActions.updateNotficationSuccess(result));
    } catch (error: any) {
      toast.error(error.message);
      throw error;
    }
  };
export const deleteOfferAction = (offerId: number) => async (dispatch: any) => {
  try {
    const response = await Http.Delete({
      path: `notification/${offerId}`,
      useAuth: true,
    });
    if (!response.ok) {
      throw new Error('Failed to delete offer');
    }

    toast.success('Deleted');
    dispatch(notficationActions.deleteNotficationSuccess(offerId));
  } catch (error: any) {
    toast.error(error.message);
    throw error;
  }
};
