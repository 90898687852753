import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Loader from './Loader';

type Props = {}

const BasicLayout = (props: Props) => {
    return (
        <Suspense fallback={<Loader />}>
            <Outlet />
        </Suspense>
    )
}

export default BasicLayout