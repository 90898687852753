import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import errorExtractor from "../utils/errorExtractor";
import { type Menu } from './../models/menu';
import Http from './../helpers/http';

type MenuState = {
    topMenuList: {
        loading: boolean,
        data: Menu[],
        error?: any
    },
    footerMenuList: {
        loading: boolean,
        data: Menu[],
        error?: any
    }
};

const initialState: MenuState = {
    topMenuList: {
        loading: false,
        data: []
    },
    footerMenuList: {
        loading: false,
        data: []
    }
};

const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        fetchTopMenuRequest: (state) => {
            state.topMenuList.loading = true;
        },
        fetchTopMenuSuccess: (state, action) => {
            state.topMenuList = {
                loading: false,
                data: action.payload
            };
        },
        fetchTopMenuFailure: (state, action) => {
            state.topMenuList.loading = false;
            state.topMenuList.error = action.payload;
        },
        createNewMenuSuccess: (state, action) => {
            if (state.topMenuList.data.length > 0) {
                state.topMenuList.data.push(action.payload);
            }

        },

        //footer menu ->start
        fetchFooterMenuRequest: (state) => {
            state.footerMenuList.loading = true;
        },
        fetchFooterMenuSuccess: (state, action) => {
            state.footerMenuList = {
                loading: false,
                data: action.payload
            };
        },
        fetchFooterMenuFailure: (state, action) => {
            state.footerMenuList.loading = false;
            state.footerMenuList.error = action.payload;
        },
        createNewFooterMenuSuccess: (state, action) => {
            if (state.footerMenuList.data.length > 0) {
                state.footerMenuList.data.push(action.payload);
            }

        },
        //footer menu ->end
    }
});

export default menuSlice.reducer;
export const menuActions = menuSlice.actions;


//OTHER ACTIONS
export const fetchTopMenuListAction = () => async (dispatch: any) => {
    dispatch(menuActions.fetchTopMenuRequest());
    try {
        const response = await Http.Get({ path: `menus`, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        dispatch(menuActions.fetchTopMenuSuccess(result.data));

    } catch (error: any) {
        dispatch(menuActions.fetchTopMenuFailure(error.message));
    }
}

//add new parent menu
export const createNewMenuAction = (data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Post({ path: `menus`, data, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        toast.success("Created");
        dispatch(menuActions.createNewMenuSuccess({ ...result, children: [] }));

    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}

//delete a menu
export const deleteMenuItemAction = (menuId: number) => async (dispatch: any) => {
    try {
        const response = await Http.Delete({ path: `menus/${menuId}`, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        dispatch(fetchTopMenuListAction());
        toast.success("Deleted");

    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}

//update menu
export const updateMenuItemAction = (menuId: number, data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Put({ path: `menus/${menuId}`, data, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        toast.success("Updated");
        dispatch(fetchTopMenuListAction());

    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}


export const rebuildMenuAction = (data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Post({ path: `menus/rebuild`, data, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        dispatch(fetchTopMenuListAction());

    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}

//create new parent footer menu
export const createNewFooterMenuAction = (data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Post({
            path: 'footer-menus',
            data,
            useAuth: true
        });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        toast.success("Created");
        dispatch(menuActions.createNewFooterMenuSuccess({ ...result, children: [] }));

    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
};

//get footer menu list
export const fetchFooterMenuListAction = () => async (dispatch: any) => {
    dispatch(menuActions.fetchFooterMenuRequest());
    try {
        const response = await Http.Get({ path: 'footer-menus', useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        dispatch(menuActions.fetchFooterMenuSuccess(result.data));

    } catch (error: any) {
        dispatch(menuActions.fetchFooterMenuFailure(error.message));
    }
}

//rebuild footer menu
export const rebuildFooterMenuAction = (data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Post({ path: 'footer-menus/rebuild', data, useAuth: true });

        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        dispatch(fetchFooterMenuListAction());

    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}

//delete a footer menu
export const deleteFooterMenuItemAction = (menuId: number) => async (dispatch: any) => {
    try {
        const response = await Http.Delete({ path: `footer-menus/${menuId}`, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        dispatch(fetchFooterMenuListAction());
        toast.success("Deleted");

    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}

//update a footer menu
export const updateFooterMenuItemAction = (menuId: number, data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Put({ path: `footer-menus/${menuId}`, data, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        toast.success("Updated");
        dispatch(fetchFooterMenuListAction());

    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}