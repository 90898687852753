import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import type { ApplicantType, ApplicantDataType, fieldsType } from '../models/career';
import Http from './../helpers/http';
import errorExtractor from '../utils/errorExtractor';

type AttributeState = {
  applicantList: {
    loading: boolean;
    data?: ApplicantType;
    error?: string | null;
  };
  applicantDetailsList: {
    loading: boolean;
    data?: ApplicantDataType & {
      question_result: fieldsType[];
    };
    error?: string | null;
  };
  categoryList: {
    loading: boolean;
    data?: {
      category: string;
      id: number;
    }[];
    error?: string;
  };
};

const initialState: AttributeState = {
  applicantList: {
    loading: false,
  },
  applicantDetailsList: {
    loading: false,
  },
  categoryList: {
    loading: false,
    data: [],
  },
};

const applicantSlice = createSlice({
  name: 'applicant',
  initialState,
  reducers: {
    fetchApplicantRequest: (state) => {
      state.applicantList.loading = true;
      state.applicantDetailsList.loading = true;
    },
    fetchApplicantSuccess: (state, action) => {
      state.applicantList = {
        loading: false,
        data: action.payload,
        error: null,
      };
      state.applicantDetailsList = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    fetchApplicantDetailsSuccess: (state, action) => {
      state.applicantDetailsList = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    fetchApplicantFailure: (state, action) => {
      state.applicantList.loading = false;
      state.applicantDetailsList.loading = true;
      state.applicantList.error = action.payload;
      state.applicantDetailsList.error = action.payload;
    },
    updateApplicantGrade: (state, action) => {
      state.applicantDetailsList = {
        loading: false,
        data: {
          ...state.applicantDetailsList.data,
          mark: action.payload?.mark,
          status: action.payload?.status,
          remaks: action.payload?.remaks,
        } as ApplicantDataType & {
          question_result: fieldsType[];
        },
        error: null,
      };
    },
    fetchApplicantCategoryRequest: (state) => {
      state.categoryList.loading = true;
    },
    fetchApplicantCategorySuccess: (state, action) => {
      state.categoryList.loading = false;
      state.categoryList.data = action.payload;
    },
    fetchApplicantCategoryFailure: (state, action) => {
      state.categoryList.loading = false;
      state.categoryList.error = action.payload;
    },
  },
});

export default applicantSlice.reducer;
export const applicantActions = applicantSlice.actions;

//OTHER ACTIONS
export const fetchApplicantAction =
  (pageNo = 1, pageSize = 25, data?: any) =>
  async (dispatch: any) => {
    dispatch(applicantActions.fetchApplicantRequest());
    try {
      const response = await Http.Post({
        path: `career/applicants?limit=${pageSize}&page=${pageNo}`,
        data: data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      dispatch(applicantActions.fetchApplicantSuccess(result));
    } catch (error: any) {
      dispatch(applicantActions.fetchApplicantFailure(error.message));
      toast.error(error.message);
    }
  };
export const fetchSingleJobApplicantAction =
  (applicantId: number, data?: any, pageNo = 1, pageSize = 25) =>
  async (dispatch: any) => {
    dispatch(applicantActions.fetchApplicantRequest());
    try {
      const response = await Http.Post({
        path: `career/${applicantId}/member/applicants?limit=${pageSize}&page=${pageNo}`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      dispatch(applicantActions.fetchApplicantSuccess(result));
    } catch (error: any) {
      dispatch(applicantActions.fetchApplicantFailure(error.message));
      toast.error(error.message);
    }
  };
export const fetchApplicantDetailsAction = (applicantId: number, id: number) => async (dispatch: any) => {
  dispatch(applicantActions.fetchApplicantRequest());
  try {
    const response = await Http.Get({
      path: `career/${applicantId}/member/${id}`,
      useAuth: true,
    });
    const result = await response.json();
    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    dispatch(applicantActions.fetchApplicantDetailsSuccess(result));
  } catch (error: any) {
    dispatch(applicantActions.fetchApplicantFailure(error.message));
  }
};
export const updateApplicantGradeAction = (data: any, applicantId: number, id: number) => async (dispatch: any) => {
  try {
    const response = await Http.Put({
      path: `career/${applicantId}/member/${id}`,
      data,
      useAuth: true,
    });
    const result = await response.json();
    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    dispatch(applicantActions.updateApplicantGrade(result));
    toast.success('Applicant Grade updated');
  } catch (error: any) {
    toast.error(error.message);
  }
};
export const deleteApplicantAction = (careerId: number, id: number, isSingleType?: boolean) => async (dispatch: any) => {
  try {
    const response = await Http.Delete({
      path: `career/${careerId}/member/${id}`,
      useAuth: true,
    });
    const result = await response.json();
    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    if (!isSingleType) {
      dispatch(fetchApplicantAction());
    } else {
      dispatch(fetchSingleJobApplicantAction(careerId));
    }
    toast.success('Applicant list updated');
  } catch (error: any) {
    toast.error(error.message);
  }
};
export const searchAllApplicantAction = (data: any) => async (dispatch: any) => {
  try {
    const response = await Http.Post({
      path: `career/applicants?limit=20&page=1`,
      data,
      useAuth: true,
    });
    const result = await response.json();
    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    dispatch(applicantActions.fetchApplicantSuccess(result));
  } catch (error: any) {
    toast.error(error.message);
  }
};

export const fetchApplicantCategoryAction = () => async (dispatch: any) => {
  dispatch(applicantActions.fetchApplicantCategoryRequest());
  try {
    const response = await Http.Get({
      path: `career/category`,
      useAuth: true,
    });
    const result = await response.json();
    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    // console.log(result);
    dispatch(applicantActions.fetchApplicantCategorySuccess(result));
  } catch (error: any) {
    dispatch(applicantActions.fetchApplicantCategoryFailure(error.message));
  }
};
