import { configureStore } from '@reduxjs/toolkit';

import categorySlice from './categorySlice';
import mediaSlice from './mediaSlice';
import productSlice from './productSlice';
import attributeSlice from './attributeSlice';
import deliverySlice from './deliverySlice';
import orderSlice from './orderSlice';
import preOrderSlice from './preOrderSlice';
import billingSlice from './billingSlice';
import homeContentSlice from './homeContentSlice';
import rewardSlice from './rewardSlice';
import offerSlice from './offerSlice';
import menuSlice from './menuSlice';
import appMenuSlice from './appMenuSlice';
import brandSlice from './brandSlice';
import pageSlice from './pageSlice';
import settingSlice from './settingSlice';
import searchSlice from './searchSlice';
import authSlice from './authSlice';
import specSlice from './specSlice';
import statisticsSlice from './statisticsSlice';
import emiSlice from './emiSlice';
import staffSlice from './staffSlice';
import customerSlice from './customerSlice';
import applicantSlice from './applicantSlice';
import careerSlice from './careerSlice';
import departmentSlice from './departmentSlice';
import paymentSlice from './paymentSlice';
import activeLogSlice from './activatyLogSlice';
import accountSlice from './accountSlice';
import salesSlice from './salesSlice';
import faqSlice from './faqSlice';
import pressCoverage from './pressCoverage';
import appContentSlice from './appContentSlice';
import appClipSlice from './appClipSlice';
import notficationSlice from './notficationSlice';
import pointSlice from './pointSlice';
import deviceSlice from './deviceSlice';

const store = configureStore({
  reducer: {
    account: accountSlice,
    category: categorySlice,
    media: mediaSlice,
    product: productSlice,
    device: deviceSlice,
    attribute: attributeSlice,
    career: careerSlice,
    applicant: applicantSlice,
    delivery: deliverySlice,
    order: orderSlice,
    sales: salesSlice,
    preOrder: preOrderSlice,
    billing: billingSlice,
    homeContent: homeContentSlice,
    appMenu: appMenuSlice,
    appContent: appContentSlice,
    appClip: appClipSlice,
    reward: rewardSlice,
    offer: offerSlice,
    menu: menuSlice,
    brand: brandSlice,
    page: pageSlice,
    setting: settingSlice,
    search: searchSlice,
    auth: authSlice,
    spec: specSlice,
    statistics: statisticsSlice,
    emi: emiSlice,
    staff: staffSlice,
    customer: customerSlice,
    department: departmentSlice,
    payment: paymentSlice,
    activeLog: activeLogSlice,
    faq: faqSlice,
    pressCoverage: pressCoverage,
    appNotfication: notficationSlice,
    point: pointSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
