import { createSlice } from "@reduxjs/toolkit";
import { type Bank, type EmiRate } from '../models/emi';
import Http from '../helpers/http';
import { toast } from "react-toastify";

type EmiState = {
    banks: {
        loading: boolean,
        data: Bank[],
        error?: string
    },
    emiRates: {
        loading: boolean,
        data: EmiRate[],
        error?: string
    }
};

const initialState: EmiState = {
    banks: {
        loading: false,
        data: []
    },
    emiRates: {
        loading: false,
        data: []
    }
};

const emiSlice = createSlice({
    name: "emi",
    initialState,
    reducers: {
        fetchBanksRequest: (state) => {
            state.banks.loading = true;
        },
        fetchBanksSuccess: (state, action) => {
            state.banks = {
                loading: false,
                data: action.payload
            };
        },
        fetchBanksFailure: (state, action) => {
            state.banks.loading = false;
            state.banks.error = action.payload;
        },
        addBank: (state, action) => {
            state.banks.data.push(action.payload);
        },
        updateBank: (state, action) => {
            state.banks.data = state.banks.data.map(bank => bank.id === action.payload.id ? action.payload : bank);
        },
        removeBank: (state, action) => {
            state.banks.data = state.banks.data.filter(bank => bank.id !== action.payload);
        },
        //EMI RATE
        fetchEmiRatesRequest: (state) => {
            state.emiRates.loading = true;
        },
        fetchEmiRatesSuccess: (state, action) => {
            state.emiRates = {
                loading: false,
                data: action.payload
            };
        },
        fetchEmiRatesFailure: (state, action) => {
            state.emiRates.loading = false;
            state.emiRates.error = action.payload;
        },
        addEmiRate: (state, action) => {
            state.emiRates.data.push(action.payload);
        },
        updateEmiRate: (state, action) => {
            state.emiRates.data = state.emiRates.data.map(emi => emi.id === action.payload.id ? ({ name: emi.name, ...action.payload }) : emi);
        },
        removeEmiRate: (state, action) => {
            state.emiRates.data = state.emiRates.data.filter(emi => emi.id !== action.payload);
        }
    }
});

export default emiSlice.reducer;
export const emiActions = emiSlice.actions;

export const fetchBanksAction = () => async (dispatch: any) => {
    dispatch(emiActions.fetchBanksRequest());
    try {
        const res = await Http.Get({
            path: `emi/banks`,
            useAuth: true
        });
        const result = await res.json();

        if (!res.ok) {
            throw new Error("Failed");
        }
        dispatch(emiActions.fetchBanksSuccess(result));

    } catch (error: any) {
        dispatch(emiActions.fetchBanksFailure(error.message));
    }
};

export const addNewBankAction = (data: any) => async (dispatch: any) => {
    try {
        const res = await Http.Post({
            path: `emi/banks`,
            data,
            useAuth: true
        });
        const result = await res.json();

        if (!res.ok) {
            throw new Error("Failed");
        }
        dispatch(emiActions.addBank(result));
        toast.success("Bank added");

    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
};

export const updateBankAction = (data: any, bankId: number) => async (dispatch: any) => {
    try {
        const res = await Http.Put({
            path: `emi/banks/${bankId}`,
            data,
            useAuth: true
        });
        const result = await res.json();

        if (!res.ok) {
            throw new Error("Failed");
        }
        dispatch(emiActions.updateBank(result));
        toast.success("Bank updated");
    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
};

export const deleteBankAction = (bankId: number) => async (dispatch: any) => {
    try {
        const res = await Http.Delete({
            path: `emi/banks/${bankId}`,
            useAuth: true
        });
        if (!res.ok) {
            throw new Error("Failed");
        }
        toast.success("Bank deleted");
        dispatch(emiActions.removeBank(bankId));
    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}


//emi rate
export const fetchEmiRatesAction = () => async (dispatch: any) => {
    dispatch(emiActions.fetchEmiRatesRequest());
    try {
        const res = await Http.Get({
            path: `emi/rates`,
            useAuth: true
        });
        const result = await res.json();

        if (!res.ok) {
            throw new Error("Failed");
        }
        dispatch(emiActions.fetchEmiRatesSuccess(result));

    } catch (error: any) {
        dispatch(emiActions.fetchEmiRatesFailure(error.message));
    }
};

export const addNewEmiRateAction = (data: any) => async (dispatch: any) => {
    try {
        const res = await Http.Post({
            path: `emi/rates`,
            data,
            useAuth: true
        });
        const result = await res.json();

        if (!res.ok) {
            throw new Error("Failed");
        }
        dispatch(emiActions.addEmiRate(result));
        toast.success("Success");

    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
};

export const updateEmiRateAction = (data: any, emiId: number) => async (dispatch: any) => {
    try {
        const res = await Http.Put({
            path: `emi/rates/${emiId}`,
            data,
            useAuth: true
        });
        const result = await res.json();

        if (!res.ok) {
            throw new Error("Failed");
        }
        dispatch(emiActions.updateEmiRate(result));
        toast.success("Bank updated");

    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
};

export const deleteEmiRateAction = (emiId: number) => async (dispatch: any) => {
    try {
        const res = await Http.Delete({
            path: `emi/rates/${emiId}`,
            useAuth: true
        });
        if (!res.ok) {
            throw new Error("Failed");
        }
        toast.success("Deleted");
        dispatch(emiActions.removeEmiRate(emiId));
    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}