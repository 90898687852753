import { useEffect, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/redux';
type Props = { path: string; children: JSX.Element };
function ProtectedRoutes(props: Props) {
  const me = useAppSelector((state) => state.auth.me);
  const navigation = useNavigate();
  useEffect(() => {
    if (!me.data) {
      navigation('/login');
    }
    // eslint-disable-next-line
  }, [me.data]);
  const permissions = useMemo(() => {
    if (me.data && me.data.permissions.length) {
      return me.data.permissions[0].permissions;
    } else {
      return [];
    }
  }, [me.data]);

  if (!permissions.includes(props?.path)) {
    return (
      <section className='grid place-content-center min-h-[calc(100vh_-_47px)] bg-white'>
        <h1 className='text-3xl text-center font-bold'>
          Welcome To <span className='text-primary'>Apple Gadgets</span>
        </h1>
        <p className='text-lg text-center mt-2'>
          We're creating something awesome!
        </p>
      </section>
    );
  }
  return props?.children;
}
export default ProtectedRoutes;
