import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Http from '../helpers/http';
import type Pagination from './../models/pagination';

import errorExtractor from '../utils/errorExtractor';
import { pressCoverageType } from '../models/pressCoverage';
type pressCoverageState = {
  PressCoverageList: {
    loading: boolean;
    list: pressCoverageType[];
    meta?: Pagination;
    error?: string;
  };
};

const initialState: pressCoverageState = {
  PressCoverageList: {
    loading: false,
    list: [],
  },
};

const pressCoverageSlice = createSlice({
  name: 'press-coverage',
  initialState,
  reducers: {
    //category list
    fetchPressCoverageRequest: (state) => {
      state.PressCoverageList.loading = true;
    },
    fetchPressCoverageSuccess: (state, action) => {
      state.PressCoverageList = {
        loading: false,
        list: action.payload.data,
        meta: action.payload.meta,
      };
    },
    fetchPressCoverageFailure: (state, action) => {
      state.PressCoverageList.loading = false;
      state.PressCoverageList.error = action.payload;
    },
  },
});

export default pressCoverageSlice.reducer;
export const pressCoverageActions = pressCoverageSlice.actions;
//category list
export const fetchPressCoverageList = () => async (dispatch: any) => {
  dispatch(pressCoverageActions.fetchPressCoverageRequest());
  try {
    const res = await Http.Get({
      path: `press`,
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed');
    }

    dispatch(
      pressCoverageActions.fetchPressCoverageSuccess({
        data: result.data,
        meta: result.meta,
      })
    );
  } catch (error: any) {
    dispatch(pressCoverageActions.fetchPressCoverageFailure(error.message));
  }
};
export const pressCoverageCreateAction =
  (data: any) => async (dispatch: any) => {
    try {
      const response = await Http.Post({
        path: `press`,
        data,
        useAuth: true,
      });
      if (!response.ok) {
        const result = await response.json();
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.success('FAQ category created success');
      dispatch(fetchPressCoverageList());
    } catch (error: any) {
      toast.error(error.message);
      dispatch(pressCoverageActions.fetchPressCoverageFailure(error.message));
    }
  };
export const pressCoverageUpdateAction =
  ({ id, data }: { id: number; data: any }) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Put({
        path: `press/${id}`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.success('FAQ category updated success');
      dispatch(fetchPressCoverageList());
    } catch (error: any) {
      toast.error(error.message);
      dispatch(pressCoverageActions.fetchPressCoverageFailure(error.message));
    }
  };
export const pressCoverageDeleteAction =
  (id: number) => async (dispatch: any) => {
    try {
      const response = await Http.Delete({
        path: `press/${id}`,
        useAuth: true,
      });
      if (!response.ok) {
        const result = await response.json();
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.success('Press Coverage deleted success');
      dispatch(fetchPressCoverageList());
    } catch (error: any) {
      toast.error(error.message);
      dispatch(pressCoverageActions.fetchPressCoverageFailure(error.message));
    }
  };
