import { createSlice } from '@reduxjs/toolkit';
import Http from './../helpers/http';
import {
  type RecentOrder,
  type MonthlySold,
  StockReport,
} from './../models/statistics';
import type Pagination from './../models/pagination';

type StatisticsState = {
  recentOrders: {
    loading: boolean;
    data: RecentOrder[];
    error?: string;
  };
  monthlySold: {
    loading: boolean;
    data: MonthlySold[];
    error?: string;
  };
  categoryReport: {
    loading: boolean;
    data: {
      collections_id: number;
      deliveredArray: string;
      collectionDetails: string;
      sell_count: string;
    }[];
    error?: string;
  };
  brandReport: {
    loading: boolean;
    data: {
      collections_id: number;
      deliveredArray: string;
      brandname: string;
      sell_count: string;
    }[];
    error?: string;
  };
  stockReport: {
    loading: boolean;
    data: StockReport[];
    error?: string;
    meta?: Pagination;
  };
  analysisReport: {
    loading: boolean;
    data?: {
      [key: string]: {
        type: string;
        count: number;
      }[];
    }[];
    error?: string;
  };
};

const initialState: StatisticsState = {
  recentOrders: {
    loading: false,
    data: [],
  },
  monthlySold: {
    loading: false,
    data: [],
  },
  categoryReport: {
    loading: false,
    data: [],
  },
  brandReport: {
    loading: false,
    data: [],
  },
  stockReport: {
    loading: false,
    data: [],
  },
  analysisReport: {
    loading: false,
  },
};

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    //recent orders -> start
    fetchRecentOrdersRequest: (state) => {
      state.recentOrders.loading = true;
    },
    fetchRecentOrdersSuccess: (state, action) => {
      state.recentOrders = {
        loading: false,
        data: action.payload,
      };
    },
    fetchRecentOrdersFailure: (state, action) => {
      state.recentOrders.loading = false;
      state.recentOrders.error = action.payload;
    },
    //recent orders -> end

    //monthly sold -> start
    fetchMonthlySoldRequest: (state) => {
      state.monthlySold.loading = true;
    },
    fetchMonthlySoldSuccess: (state, action) => {
      state.monthlySold = {
        loading: false,
        data: action.payload,
      };
    },
    fetchMonthlySoldFailure: (state, action) => {
      state.monthlySold.loading = false;
      state.monthlySold.error = action.payload;
    },
    //monthly sold -> end

    //Category report -> start
    fetchCategoryReportRequest: (state) => {
      state.categoryReport.loading = true;
    },
    fetchCategoryReportSuccess: (state, action) => {
      state.categoryReport = {
        loading: false,
        data: action.payload ? action.payload : [],
      };
    },
    fetchCategoryReportFailure: (state, action) => {
      state.categoryReport.loading = false;
      state.categoryReport.data = [];
      state.categoryReport.error = action.payload;
    },
    //Brand report -> start
    fetchBrandReportRequest: (state) => {
      state.brandReport.loading = true;
    },
    fetchBrandReportSuccess: (state, action) => {
      state.brandReport = {
        loading: false,
        data: action.payload ? action.payload : [],
      };
    },
    fetchBrandReportFailure: (state, action) => {
      state.brandReport.loading = false;
      state.brandReport.data = [];
      state.brandReport.error = action.payload;
    },

    //product stock report -> start
    fetchStockReportRequest: (state) => {
      state.stockReport.loading = true;
    },
    fetchStockReportSuccess: (state, action) => {
      state.stockReport = {
        loading: false,
        data: action.payload.data,
        meta: action.payload.meta,
      };
    },
    fetchStockReportFailure: (state, action) => {
      state.stockReport.loading = false;
      state.stockReport.error = action.payload;
    },
    //product analysis report -> start
    fetchAnalysisReportRequest: (state) => {
      state.analysisReport.loading = true;
    },
    fetchAnalysisReportSuccess: (state, action) => {
      state.analysisReport = {
        loading: false,
        data: action.payload,
      };
    },
    fetchAnalysisReportFailure: (state, action) => {
      state.analysisReport.loading = false;
      state.analysisReport.error = action.payload;
    },
  },
});

export default statisticsSlice.reducer;
export const statisticsActions = statisticsSlice.actions;

export const fetchRecentOrdersAction =
  ({ cache, period }: { cache: boolean; period: number }) =>
  async (dispatch: any) => {
    dispatch(statisticsActions.fetchRecentOrdersRequest());
    try {
      const res = await Http.Get({
        path: `reports/orders?cache=${cache ? '1' : '0'}&period=${period}`,
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed');
      }
      dispatch(statisticsActions.fetchRecentOrdersSuccess(result.report));
    } catch (error: any) {
      dispatch(statisticsActions.fetchRecentOrdersFailure(error.message));
    }
  };

export const fetchMonthlySoldAction =
  ({ cache }: { cache: boolean }) =>
  async (dispatch: any) => {
    dispatch(statisticsActions.fetchMonthlySoldRequest());
    try {
      const res = await Http.Get({
        path: `reports/orders/monthly?cache=${cache ? '1' : '0'}`,
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed');
      }

      dispatch(statisticsActions.fetchMonthlySoldSuccess(result.report));
    } catch (error: any) {
      dispatch(statisticsActions.fetchMonthlySoldFailure(error.message));
    }
  };
export const fetchCategoryReportAction =
  (data?: { date_from: string; date_to: string }) => async (dispatch: any) => {
    dispatch(statisticsActions.fetchCategoryReportRequest());
    try {
      const res = await Http.Post({
        path: `reports/collections/selling`,
        data: data ? data : {},
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed');
      }
      dispatch(
        statisticsActions.fetchCategoryReportSuccess(result.sellsreport)
      );
    } catch (error: any) {
      dispatch(statisticsActions.fetchCategoryReportFailure(error.message));
    }
  };

export const fetchBrandReportAction =
  (data?: { date_from: string; date_to: string }) => async (dispatch: any) => {
    dispatch(statisticsActions.fetchBrandReportRequest());
    try {
      const res = await Http.Post({
        path: `reports/brand/selling`,
        data: data ? data : {},
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed');
      }
      dispatch(statisticsActions.fetchBrandReportSuccess(result.sellsreport));
    } catch (error: any) {
      dispatch(statisticsActions.fetchBrandReportFailure(error.message));
    }
  };

export const fetchStockReportAction =
  ({
    pageNo = 1,
    pageSize = 20,
    data,
  }: {
    pageNo?: number;
    pageSize?: number;
    data?: { date_from: string; date_to: string };
  }) =>
  async (dispatch: any) => {
    dispatch(statisticsActions.fetchStockReportRequest());
    try {
      const res = await Http.Post({
        path: `orders/alart?page=${pageNo}&limit=${pageSize}`,
        data: data ? data : {},
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed');
      }
      dispatch(statisticsActions.fetchStockReportSuccess(result));
    } catch (error: any) {
      dispatch(statisticsActions.fetchStockReportFailure(error.message));
    }
  };

export const fetchAnalysisReportAction =
  ({ cache = true, data }: { cache: boolean; data?: any }) =>
  async (dispatch: any) => {
    dispatch(statisticsActions.fetchAnalysisReportRequest());
    try {
      const res = await Http.Post({
        path: `reports/ods?cache=${cache ? '1' : '0'}`,
        data: data ? data : {},
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed');
      }
      dispatch(statisticsActions.fetchAnalysisReportSuccess(result));
    } catch (error: any) {
      dispatch(statisticsActions.fetchAnalysisReportFailure(error.message));
    }
  };
