import { createSlice } from '@reduxjs/toolkit';
import type { Order, ReportType } from './../models/order';
import type Pagination from './../models/pagination';
import { toast } from 'react-toastify';
import Http from './../helpers/http';
import errorExtractor from '../utils/errorExtractor';

type OrderState = {
  salesReportList: {
    loading: boolean;
    data?: {
      orders: Order[];
      reports:ReportType};
    error?: string | null;
    meta?: Pagination;
  };
};

const initialState: OrderState = {
  salesReportList:{
    loading: false,
  }
};

const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    //Sales Report
    fetchSalesReportListRequest: (state) => {
      state.salesReportList.loading = true;
    },
    fetchSalesReportListSuccess: (state, action) => {
      state.salesReportList = {
        loading: false,
        data: {
          orders: action.payload.orders,
          reports: action.payload.reports
        },
      };
    },
    fetchSalesReportFailure: (state, action) => {
      state.salesReportList.loading = false;
      state.salesReportList.error = action.payload;
    },
  },
});

export default salesSlice.reducer;
export const salesActions = salesSlice.actions;

export const searchSalesReportAction = ({id,data,pageNo,
  pageSize}:{id: number,data?: any, pageNo: number,pageSize: number}) => async (dispatch: any) => {
    dispatch(salesActions.fetchSalesReportListRequest());
  try {
    const response = await Http.Post({ path: `staffs/sellsreport/${id}?page=${pageNo}&limit=${pageSize}`, data:{
      ...data,
      paginate:1
    }, useAuth: true });
    const result = await response.json();
    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    dispatch(salesActions.fetchSalesReportListSuccess({
        orders: result.orders,
        reports: result.reports,
    }));
    return result;
  } catch (error: any) {
    dispatch(salesActions.fetchSalesReportFailure(error));
    toast.error(error.message);
  }
};