import { createSlice } from '@reduxjs/toolkit';
import Http from '../helpers/http';
import { type Customer } from '../models/customer';
import type Pagination from './../models/pagination';

type CustomerState = {
  customerList: {
    loading: boolean;
    data: Customer[];
    meta?: Pagination;
    error?: string;
  };
};

const initialState: CustomerState = {
  customerList: {
    loading: false,
    data: [],
  },
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    fetchCustomerListRequest: (state) => {
      state.customerList.loading = true;
    },
    fetchCustomerListSuccess: (state, action) => {
      state.customerList = {
        loading: false,
        data: action.payload.data,
        meta: action.payload.meta,
      };
    },
    fetchCustomerListFailure: (state, action) => {
      state.customerList.loading = false;
      state.customerList.error = action.payload;
    },
  },
});

export default customerSlice.reducer;
export const customerActions = customerSlice.actions;

export const fetchCustomerListAction =
  (pageNo?: number) => async (dispatch: any) => {
    dispatch(customerActions.fetchCustomerListRequest());
    try {
      const res = await Http.Get({
        path: `customers${pageNo ? `?page=${pageNo}` : ''}`,
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed to fetch customer list');
      }

      dispatch(
        customerActions.fetchCustomerListSuccess({
          data: result.data,
          meta: result.meta,
        })
      );
    } catch (error: any) {
      dispatch(customerActions.fetchCustomerListFailure(error.message));
    }
  };

export const searchCustomersAction =
  ({ data, pageNo = 1 }: { data: any; pageNo?: number }) =>
  async (dispatch: any) => {
    dispatch(customerActions.fetchCustomerListRequest());
    try {
      const res = await Http.Post({
        path: `customers/search${pageNo ? `?page=${pageNo}` : ''}`,
        data,
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed to fetch customer list');
      }

      dispatch(
        customerActions.fetchCustomerListSuccess({
          data: result.data,
          meta: result.meta ? result.meta : undefined,
        })
      );
    } catch (error: any) {
      dispatch(customerActions.fetchCustomerListFailure(error.message));
    }
  };
