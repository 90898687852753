import { createSlice } from '@reduxjs/toolkit';
import Http from './../helpers/http';
import { type Reward, type Rates } from './../models/reward';
import type Pagination from './../models/pagination';
import { type Coupon } from './../models/coupon';
import errorExtractor from '../utils/errorExtractor';
import { toast } from 'react-toastify';
import { Staff } from '../models/staff';

type RewardState = {
  rewardList: {
    loading: boolean;
    data: Reward[];
    error?: any;
    meta?: Pagination;
  };
  reward: {
    loading: boolean;
    list?: {
      id: number;
      values: number;
      customer_id: number;
      purchasable_id: number | null;
      order_id: number | null;
      details: string;
      created_by: string;
      created_at: string;
      updated_at: string;
      staff: Staff;
    }[];
    allTimePoints?: number;
    totalPoints?: string;
    customer?: {
      id: number;
      first_name: string;
      last_name: string;
      points: number;
    };
    error?: any;
    meta?: Pagination;
  };
  rates: {
    loading: boolean;
    data: Rates[];
    error?: any;
  };
  couponList: {
    loading: boolean;
    data: Coupon[];
    error?: string;
    meta?: Pagination;
  };
};

const initialState: RewardState = {
  rewardList: {
    loading: false,
    data: [],
  },
  reward: {
    loading: false,
  },
  rates: {
    loading: false,
    data: [],
  },
  couponList: {
    loading: false,
    data: [],
  },
};

const rewardSlice = createSlice({
  name: 'reward',
  initialState,
  reducers: {
    fetchRewardListRequest: (state) => {
      state.rewardList.loading = true;
    },
    fetchRewardRequest: (state) => {
      state.reward.loading = true;
    },
    fetchRewardListSuccess: (state, action) => {
      state.rewardList = {
        loading: false,
        data: action.payload.data,
        error: null,
        meta: action.payload.meta,
      };
    },
    fetchRewardSuccess: (state, action) => {
      state.reward = {
        loading: false,
        list: action.payload.pointsList,
        customer: action.payload.customer,
        allTimePoints: action.payload.allTimePoints,
        totalPoints: action.payload.totalPoints,
        error: null,
        meta: action.payload.meta,
      };
    },
    fetchRewardListFailure: (state, action) => {
      state.rewardList.loading = false;
      state.rewardList.error = action.payload;
    },
    fetchRewardFailure: (state, action) => {
      state.reward.loading = false;
      state.reward.error = action.payload;
    },
    updateRewardPointSuccess: (state, action) => {
      state.reward.list = [action.payload, ...state.reward.list!];
      state.reward.allTimePoints =
        state.reward.allTimePoints + action.payload.values;
    },
    //RATES
    fetchRatesRequest: (state) => {
      state.rates.loading = true;
      state.rates.error = undefined;
    },
    fetchRatesSuccess: (state, action) => {
      state.rates = {
        loading: false,
        data: action.payload,
        error: undefined,
      };
    },
    fetchRatesFailure: (state, action) => {
      state.rates.loading = false;
      state.rates.error = action.payload;
    },
    updateRates: (state, action) => {
      state.rates.data = state.rates.data.map((obj) => {
        if (obj.id === action.payload.id) {
          return {
            ...obj,
            exchange_rate: action.payload.exchange_rate,
            purchase_rate: action.payload.purchase_rate,
          };
        }
        return obj;
      });
    },
    //---------------COUPON--------------
    fetchCouponListRequest: (state) => {
      state.couponList.loading = true;
    },
    fetchCouponListSuccess: (state, action) => {
      state.couponList = {
        loading: false,
        data: action.payload.data,
        meta: action.payload.meta,
      };
    },
    fetchCouponListFailure: (state, action) => {
      state.couponList.loading = false;
      state.couponList.error = action.payload;
    },
    addCoupon: (state, action) => {
      if (state.couponList.data.length) {
        state.couponList.data.push(action.payload);
      }
    },
    updateCoupon: (state, action) => {
      state.couponList.data = state.couponList.data.map((coupon) =>
        coupon.id === action.payload.id ? action.payload : coupon
      );
    },
    removeCoupon: (state, action) => {
      state.couponList.data = state.couponList.data.filter(
        (coupon) => coupon.id !== action.payload
      );
    },
  },
});

export default rewardSlice.reducer;
export const rewardActions = rewardSlice.actions;

//OTHER ACTIONS
export const fetchRewardListAction =
  (page: number = 1) =>
  async (dispatch: any) => {
    dispatch(rewardActions.fetchRewardListRequest());
    try {
      const response = await Http.Get({
        path: `customer-points?page=${page}`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }

      dispatch(rewardActions.fetchRewardListSuccess(result));
    } catch (error: any) {
      dispatch(rewardActions.fetchRewardListFailure(error.message));
    }
  };
export const fetchRewardAction =
  (customerId: number, page: number = 1) =>
  async (dispatch: any) => {
    dispatch(rewardActions.fetchRewardRequest());
    try {
      const response = await Http.Get({
        path: `customer-points/list/${customerId}?page=${page}`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }

      dispatch(rewardActions.fetchRewardSuccess(result));
      return result;
    } catch (error: any) {
      dispatch(rewardActions.fetchRewardFailure(error.message));
    }
  };

export const seachRewardPointAction = (data: any) => async (dispatch: any) => {
  dispatch(rewardActions.fetchRewardListRequest());
  try {
    const response = await Http.Post({
      path: `customers/search`,
      data,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      if (typeof result.error === 'string') {
        throw new Error(result.error);
      } else {
        throw new Error(errorExtractor(result.error)[0]);
      }
    }
    dispatch(rewardActions.fetchRewardListSuccess(result));
  } catch (error: any) {
    toast.error(error.message);
    dispatch(rewardActions.fetchRewardListFailure(error.message));
  }
};
export const updateRewardPointAction =
  (userId: number, data: any) => async (dispatch: any) => {
    try {
      const response = await Http.Put({
        path: `customer-points/${userId}`,
        data,
        useAuth: true,
      });
      const result = await response.json();

      if (!response.ok) {
        if (typeof result.error === 'string') {
          throw new Error(result.error);
        } else {
          throw new Error(errorExtractor(result.error)[0]);
        }
      }
      toast.success('Updated');
      dispatch(rewardActions.updateRewardPointSuccess(result));
    } catch (error: any) {
      toast.error(error.message);
      throw error;
    }
  };

//RATES
export const fetchRatesAction = () => async (dispatch: any) => {
  dispatch(rewardActions.fetchRatesRequest());
  try {
    const response = await Http.Get({
      path: `customer-points/rates`,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      throw new Error('Failed');
    }

    dispatch(rewardActions.fetchRatesSuccess(result));
  } catch (error: any) {
    dispatch(rewardActions.fetchRatesFailure(error.message));
  }
};

export const updateRatesAction = (data: any) => async (dispatch: any) => {
  try {
    const response = await Http.Post({
      path: `customer-points/exchange-rate`,
      data,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      throw new Error('Failed');
    }
    // console.log(result);
    dispatch(rewardActions.updateRates(result));
    toast.success('Updated');
  } catch (error: any) {
    toast.error(error.message);
  }
};

//---------------COUPON-------------
export const fetchCouponListAction = () => async (dispatch: any) => {
  dispatch(rewardActions.fetchCouponListRequest());
  try {
    const res = await Http.Get({
      path: 'coupons',
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed');
    }
    dispatch(rewardActions.fetchCouponListSuccess(result));
  } catch (error: any) {
    dispatch(rewardActions.fetchCouponListFailure(error.message));
  }
};

export const deleteCouponAction =
  (couponId: number) => async (dispatch: any) => {
    try {
      const res = await Http.Delete({
        path: `coupons/${couponId}`,
        useAuth: true,
      });
      await res.json();
      if (!res.ok) {
        throw new Error('Failed');
      }

      dispatch(rewardActions.removeCoupon(couponId));
      toast.success('Deleted');
    } catch (error: any) {
      toast.error(error.message);
    }
  };
