import { createSlice } from '@reduxjs/toolkit';
import Http from '../helpers/http';
import { toast } from 'react-toastify';
import errorExtractor from '../utils/errorExtractor';
import { pointType } from '../models/point';
type pressCoverageState = {
  pointList: {
    loading: boolean;
    data: pointType[];
    error?: string;
  };
};

const initialState: pressCoverageState = {
  pointList: {
    loading: false,
    data: [],
  },
};

const pointSlice = createSlice({
  name: 'points',
  initialState,
  reducers: {
    //category list
    fetchPointRequest: (state) => {
      state.pointList.loading = true;
    },
    fetchPointSuccess: (state, action) => {
      state.pointList = {
        loading: false,
        data: action.payload,
      };
    },
    fetchPointFailure: (state, action) => {
      state.pointList.loading = false;
      state.pointList.error = action.payload;
    },
  },
});

export default pointSlice.reducer;
export const pointActions = pointSlice.actions;
//category list
export const fetchPointList = () => async (dispatch: any) => {
  dispatch(pointActions.fetchPointRequest());
  try {
    const res = await Http.Get({
      path: `compac`,
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed');
    }

    dispatch(pointActions.fetchPointSuccess(result));
  } catch (error: any) {
    dispatch(pointActions.fetchPointFailure(error.message));
  }
};
export const createPointAction = (data: any) => async (dispatch: any) => {
  try {
    const response = await Http.Post({
      path: `compac`,
      data,
      useAuth: true,
    });
    if (!response.ok) {
      const result = await response.json();
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    toast.success('point created success');
    dispatch(fetchPointList());
  } catch (error: any) {
    toast.error(error.message);
    dispatch(pointActions.fetchPointFailure(error.message));
  }
};
export const updatePointAction =
  ({ id, data }: { id: number; data: any }) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Put({
        path: `compac/${id}`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.success('FAQ category updated success');
      dispatch(fetchPointList());
    } catch (error: any) {
      toast.error(error.message);
      dispatch(pointActions.fetchPointFailure(error.message));
    }
  };
export const deletePointAction = (id: number) => async (dispatch: any) => {
  try {
    const response = await Http.Delete({
      path: `compac/${id}`,
      useAuth: true,
    });
    if (!response.ok) {
      const result = await response.json();
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    toast.success('point deleted success');
    dispatch(fetchPointList());
  } catch (error: any) {
    toast.error(error.message);
    dispatch(pointActions.fetchPointFailure(error.message));
  }
};
