import Http from '../helpers/http';
import { createSlice } from '@reduxjs/toolkit';
import type Pagination from './../models/pagination';
import { activatyLogType } from '../models/activeLog';

export type ActiveLogState = {
  activatyLog: {
    loading: boolean;
    meta?: Pagination;
    list: activatyLogType[];
    error?: string | null;
    isSearching: boolean;
  };
  singleActivatyLog: {
    loading: boolean;
    data?: activatyLogType;
    error?: string | null;
  };
};

const initialState: ActiveLogState = {
  activatyLog: {
    loading: false,
    list: [],
    isSearching: false,
  },
  singleActivatyLog: {
    loading: false,
  },
};
const activeLogSlice = createSlice({
  name: 'active-log',
  initialState,
  reducers: {
    //Activaty Log
    activatyLogRequest: (state) => {
      state.activatyLog.loading = true;
    },
    activatyLogSuccess: (state, action) => {
      state.activatyLog = {
        loading: false,
        list: action.payload.data,
        meta: action.payload.meta,
        isSearching: action.payload.isSearching,
      };
    },
    activatyLogFailure: (state, action) => {
      state.activatyLog = {
        loading: false,
        list: [],
        error: action.payload,
        isSearching: false,
      };
    },
    //Activaty single Log
    singleActivatyLogRequest: (state) => {
      state.singleActivatyLog.loading = true;
    },
    singleActivatyLogSuccess: (state, action) => {
      state.singleActivatyLog = {
        loading: false,
        data: action.payload.data,
      };
    },
    singleActivatyLogFailure: (state, action) => {
      state.singleActivatyLog = {
        loading: false,
        error: action.payload,
      };
    },
  },
});

export const productActions = activeLogSlice.actions;
export default activeLogSlice.reducer;

export const fetchActivatyLogAction =
  (pageNo = 1) =>
  async (dispatch: any) => {
    dispatch(productActions.activatyLogRequest());
    try {
      const response = await Http.Get({
        path: `activaty?page=${pageNo}`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      dispatch(
        productActions.activatyLogSuccess({
          data: result.data.length ? result.data : [],
          meta:
            result?.meta && result.meta?.links.length
              ? {
                  current_page: result.meta.current_page,
                  first_page_url: result.meta.first_page_url,
                  from: result.meta.from,
                  last_page: result.meta.last_page,
                  last_page_url: result.meta.last_page_url,
                  links: result.meta.links,
                  next_page_url: result.meta.next_page_url,
                  path: result.meta.path,
                  per_page: result.meta.per_page,
                  prev_page_url: result.meta.prev_page_url,
                  to: result.meta.to,
                  total: result.meta.total,
                }
              : undefined,
        })
      );
    } catch (error: any) {
      dispatch(productActions.activatyLogFailure(error.message));
    }
  };

export const fetchSingleActivatyLogAction =
  (id: string) => async (dispatch: any) => {
    dispatch(productActions.singleActivatyLogRequest());
    try {
      const response = await Http.Get({
        path: `activaty/${id}`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      dispatch(
        productActions.singleActivatyLogSuccess({
          data: result ? result : undefined,
        })
      );
    } catch (error: any) {
      dispatch(productActions.singleActivatyLogFailure(error.message));
    }
  };
export const fetchSearchActivatyLogAction =
  (data: any) => async (dispatch: any) => {
    dispatch(productActions.activatyLogRequest());
    try {
      const response = await Http.Post({
        path: `activaty/search`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      dispatch(
        productActions.activatyLogSuccess({
          data: result.data.length ? result.data : [],
          isSearching: true,
          meta:
            result?.meta && result.meta?.links.length
              ? {
                  current_page: result.meta.current_page,
                  first_page_url: result.meta.first_page_url,
                  from: result.meta.from,
                  last_page: result.meta.last_page,
                  last_page_url: result.meta.last_page_url,
                  links: result.meta.links,
                  next_page_url: result.meta.next_page_url,
                  path: result.meta.path,
                  per_page: result.meta.per_page,
                  prev_page_url: result.meta.prev_page_url,
                  to: result.meta.to,
                  total: result.meta.total,
                }
              : undefined,
        })
      );
    } catch (error: any) {
      dispatch(productActions.activatyLogFailure(error.message));
    }
  };
