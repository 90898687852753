import { createSlice } from "@reduxjs/toolkit";
import { type SearchTerm } from './../models/search';
import type PaginationModel from './../models/pagination';
import Http from './../helpers/http';
import { toast } from "react-toastify";

type SearchState = {
    terms: {
        loading: boolean,
        data: SearchTerm[],
        error?: string,
        pagination?: PaginationModel
    }
};

const initialState: SearchState = {
    terms: {
        loading: false,
        data: []
    }
};

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        fetchTermsRequest: (state) => {
            state.terms.loading = true;
        },
        fetchTermsSuccess: (state, action) => {
            state.terms = {
                loading: false,
                data: action.payload.data,
                pagination: action.payload.meta
            };
        },
        fetchTermsFailure: (state, action) => {
            state.terms.loading = false;
            state.terms.error = action.payload;
        }
    }
});

export default searchSlice.reducer;
export const searchActions = searchSlice.actions;

export const fetchSearchTermsAction = (pageNo?: number) => async (dispatch: any) => {
    dispatch(searchActions.fetchTermsRequest());
    try {
        const res = await Http.Get({
            path: `search/terms${pageNo ? `?page=${pageNo}` : ''}`,
            useAuth: true
        });
        if (!res.ok) {
            throw new Error("failed");
        }
        const result = await res.json();
        dispatch(searchActions.fetchTermsSuccess({ data: result.data, meta: result.meta }));

    } catch (error: any) {
        dispatch(searchActions.fetchTermsFailure(error.message));
    }
};
export const addNewSearchTermAction = (data: any) => async (dispatch: any) => {
    try {
        const res = await Http.Post({
            path: 'search/terms',
            data,
            useAuth: true
        });
        if (!res.ok) {
            throw new Error("Fail to create new term");
        }
        const result = await res.json();
        dispatch(searchActions.fetchTermsSuccess({ data: result.data, meta: result.meta }));

        toast.success("Added");
    } catch (error: any) {
        toast.error(error.message);
    }
};
export const updateSearchTermAction = (id: number, data: any) => async (dispatch: any) => {
    try {
        const res = await Http.Put({
            path: `search/terms/${id}`,
            data,
            useAuth: true
        });
        if (!res.ok) {
            throw new Error("Fail to update!");
        }
        const result = await res.json();
        dispatch(searchActions.fetchTermsSuccess({ data: result.data, meta: result.meta }));
        toast.success("Updated");

    } catch (error: any) {
        toast.error(error.message);
    }
}
export const deleteSearchTermAction = (id: number) => async (dispatch: any) => {
    try {
        const res = await Http.Delete({
            path: `search/terms/${id}`,
            useAuth: true
        });
        if (!res.ok) {
            throw new Error("Fail to delete!");
        }
        const result = await res.json();
        dispatch(searchActions.fetchTermsSuccess({ data: result.data, meta: result.meta }));
        toast.success("Deleted");
    } catch (error: any) {
        toast.error(error.message);
    }
}