import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { type Staff } from '../models/staff';
import Http from '../helpers/http';
export type telesaleSellList = {
  staffDetails: {
    id: number;
    admin: number;
    firstname: string;
    lastname: string;
    email: string;
    email_verified_at: null;
    department_id: number;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
  };
  orderCount: [
    {
      count: number;
    }
  ];
  productCount: [
    {
      count: number;
    }
  ];
  productAmmount: [
    {
      sum: string | null;
    }
  ];
};
type StaffState = {
  staffList: {
    loading: boolean;
    data: Staff[];
    error?: string;
  };
  orderStaffList: {
    loading: boolean;
    data: Staff[];
    error?: string;
  };
  orderSuperviseList: {
    loading: boolean;
    data: Staff[];
    error?: string;
  };
  telesalesStaffList: {
    loading: boolean;
    data: telesaleSellList[];
    error?: string;
  };
};

const initialState: StaffState = {
  staffList: {
    loading: false,
    data: [],
  },
  orderStaffList: {
    loading: false,
    data: [],
  },
  orderSuperviseList: {
    loading: false,
    data: [],
  },
  telesalesStaffList: {
    loading: false,
    data: [],
  },
};

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    fetchStaffListRequest: (state) => {
      state.staffList.loading = true;
    },
    fetchStaffListSuccess: (state, action) => {
      state.staffList = {
        loading: false,
        data: action.payload,
      };
    },
    filterStaffListSuccess: (state, action) => {
      state.staffList = {
        loading: false,
        data: action.payload,
      };
    },
    fetchStaffListFailure: (state, action) => {
      state.staffList.loading = false;
      state.staffList.error = action.payload;
    },
    // order staff list
    fetchOrderStaffListRequest: (state) => {
      state.orderStaffList.loading = true;
    },
    fetchOrderStaffListSuccess: (state, action) => {
      state.orderStaffList = {
        loading: false,
        data: action.payload,
      };
    },
    fetchOrderStaffListFailure: (state, action) => {
      state.orderStaffList.loading = false;
      state.orderStaffList.error = action.payload;
    },
    // order Supervise list
    fetchOrderSuperviseListRequest: (state) => {
      state.orderSuperviseList.loading = true;
    },
    fetchOrderSuperviseListSuccess: (state, action) => {
      state.orderSuperviseList = {
        loading: false,
        data: action.payload,
      };
    },
    fetchOrderSuperviseListFailure: (state, action) => {
      state.orderSuperviseList.loading = false;
      state.orderSuperviseList.error = action.payload;
    },
    // update
    updateStaff: (state, action) => {
      state.staffList.data = state.staffList.data.map((staff) =>
        staff.id === action.payload.id ? action.payload : staff
      );
    },
    removeStaff: (state, action) => {
      state.staffList.loading = false;
      state.staffList.data = state.staffList.data.filter(
        (staff) => staff.id !== action.payload
      );
    },
    // order Supervise list
    fetchTelesalesStaffListRequest: (state) => {
      state.telesalesStaffList.loading = true;
    },
    fetchTelesalesStaffListSuccess: (state, action) => {
      state.telesalesStaffList = {
        loading: false,
        data: action.payload,
      };
    },
    fetchTelesalesStaffListFailure: (state, action) => {
      state.telesalesStaffList.loading = false;
      state.telesalesStaffList.error = action.payload;
    },
  },
});

export default staffSlice.reducer;
export const staffActions = staffSlice.actions;

export const fetchStaffListAction = () => async (dispatch: any) => {
  dispatch(staffActions.fetchStaffListRequest());
  try {
    const res = await Http.Get({
      path: 'staffs',
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed');
    }

    dispatch(staffActions.fetchStaffListSuccess(result));
  } catch (error: any) {
    dispatch(staffActions.fetchStaffListFailure(error.message));
  }
};
export const filterStaffListAction = (data: any) => async (dispatch: any) => {
  dispatch(staffActions.fetchStaffListRequest());
  try {
    const res = await Http.Patch({
      path: 'staffs',
      data,
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed');
    }

    dispatch(staffActions.filterStaffListSuccess(result));
  } catch (error: any) {
    dispatch(staffActions.fetchStaffListFailure(error.message));
  }
};

export const updateStaffPermissionsAction =
  (data: any, staffId: number) => async (dispatch: any) => {
    try {
      const res = await Http.Put({
        path: `staffs/permissions/${staffId}`,
        data,
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed');
      }

      dispatch(staffActions.updateStaff(result));
      toast.success('Updated');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

export const deleteStaffAction = (staffId: number) => async (dispatch: any) => {
  dispatch(staffActions.fetchOrderStaffListRequest());
  try {
    const res = await Http.Delete({
      path: `staffs/${staffId}`,
      useAuth: true,
    });

    if (!res.ok) {
      throw new Error('Failed');
    }

    dispatch(staffActions.removeStaff(staffId));
    toast.success('Deleted');
  } catch (error: any) {
    dispatch(staffActions.fetchOrderStaffListFailure(error.message));
    toast.error(error.message);
  }
};

export const fetchTelesalesStaffAction = () => async (dispatch: any) => {
  dispatch(staffActions.fetchOrderStaffListRequest());
  try {
    const res = await Http.Get({
      path: 'staffs/dept/telesales_staff',
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed');
    }
    dispatch(staffActions.fetchOrderStaffListSuccess(result));
  } catch (error: any) {
    dispatch(staffActions.fetchOrderStaffListFailure(error.message));
  }
};
export const fetchTelesalesSuperviseAction = () => async (dispatch: any) => {
  dispatch(staffActions.fetchOrderSuperviseListRequest());
  try {
    const res = await Http.Get({
      path: 'staffs/dept/sales_staff',
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed');
    }
    dispatch(staffActions.fetchOrderSuperviseListSuccess(result));
  } catch (error: any) {
    dispatch(staffActions.fetchOrderSuperviseListFailure(error.message));
  }
};

export const fetchTelesalesListAction =
  (data?: any) => async (dispatch: any) => {
    dispatch(staffActions.fetchTelesalesStaffListRequest());
    try {
      const res = await Http.Post({
        path: 'staffs/sellsstate',
        data: {
          ...data,
          paginate: 1,
        },
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed');
      }
      dispatch(staffActions.fetchTelesalesStaffListSuccess(result));
    } catch (error: any) {
      dispatch(staffActions.fetchTelesalesStaffListFailure(error.message));
    }
  };
