import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import errorExtractor from '../utils/errorExtractor';
import { type Page } from './../models/page';
import Http from './../helpers/http';


type PageState = {
    pageList: {
        loading: boolean,
        data: Page[],
        error?: string
    }
};

const initialState: PageState = {
    pageList: {
        loading: false,
        data: []
    }
};

const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        fetchAllPageRequest: (state) => {
            state.pageList.loading = true;
        },
        fetchAllPageSuccess: (state, action) => {
            state.pageList = {
                loading: false,
                data: action.payload
            };
        },
        fetchAllPageFailure: (state, action) => {
            state.pageList.loading = false;
            state.pageList.error = action.payload;
        },
        addNewPage: (state, action) => {
            state.pageList.data.length && state.pageList.data.push(action.payload)
        },
        updatePage: (state, action) => {
            state.pageList.data = state.pageList.data.map(page => page.id === action.payload.id ? action.payload : page);
        },
        removePage: (state, action) => {
            state.pageList.data = state.pageList.data.filter(page => page.id !== action.payload);
        }
    }
});

export default pageSlice.reducer;
export const pageActions = pageSlice.actions;


//OTHER ACTIONS
export const fetchAllPageAction = () => async (dispatch: any) => {
    dispatch(pageActions.fetchAllPageRequest());
    try {
        const res = await Http.Get({ path: `pages`, useAuth: true });
        const result = await res.json();
        if (!res.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        dispatch(pageActions.fetchAllPageSuccess(result.data));

    } catch (error: any) {
        dispatch(pageActions.fetchAllPageFailure(error.message));
    }
};

export const createNewPageAction = (data: any) => async (dispatch: any) => {
    try {
        const res = await Http.Post({ path: `pages`, data, useAuth: true });
        const result = await res.json();
        if (!res.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        dispatch(pageActions.addNewPage(result));

    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
};

export const updatePageAction = (pageId: number, data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Put({
            path: `pages/${pageId}`,
            data,
            useAuth: true
        });
        const result = await response.json();
        if (!response.ok) {
            throw new Error("failed");

        }
        dispatch(pageActions.updatePage(result));

    } catch (error: any) {
        toast.error(error.message);
    }
};
export const deletePageAction = (pageId: number) => async (dispatch: any) => {
    try {
        const response = await Http.Delete({
            path: `pages/${pageId}`,
            useAuth: true
        });
        if (!response.ok) {
            throw new Error("failed");
        }
        dispatch(pageActions.removePage(pageId));

    } catch (error: any) {
        toast.error(error.message);
    }
};