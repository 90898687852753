export const baseApiUrl = `https://adminapi.applegadgetsbd.com/api/`;
export const apiBaseUrl = `https://storeapi.applegadgetsbd.com/api/`;
const baseMediaPath = `https://adminapi.applegadgetsbd.com/`;
export const baseFilePath = `https://file.tjlsbd.com/storage/uploads/`;
export const preOrderImagePath = `${baseMediaPath}storage/media/preorder/`;
export const mediaPath = {
  large: `${baseMediaPath}storage/media/large/`,
  medium: `${baseMediaPath}storage/media/medium/`,
  thumb: `${baseMediaPath}storage/media/thumb/`,
};
export const tinyMceKey = `7x60os591sch2qpe248d5aspuy7snkj1ikbbovsy2hin80to`; // jahid vai
