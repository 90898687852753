import AppNavigator from './navigations';
import { ToastContainer } from 'react-toastify';

function App() {
  return <>
    <AppNavigator />
    <ToastContainer />
  </>;
}

export default App;