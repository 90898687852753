import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import errorExtractor from '../utils/errorExtractor';
import { type AppMenu } from './../models/menu';
import Http from './../helpers/http';

type MenuState = {
  menuList: {
    loading: boolean;
    data: AppMenu[];
    error?: any;
  };
};

const initialState: MenuState = {
  menuList: {
    loading: false,
    data: [],
  },
};

const appMenuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    fetchMenuRequest: (state) => {
      state.menuList.loading = true;
    },
    fetchMenuSuccess: (state, action) => {
      state.menuList = {
        loading: false,
        data: action.payload,
      };
    },
    fetchMenuFailure: (state, action) => {
      state.menuList.loading = false;
      state.menuList.error = action.payload;
    },
    createNewMenuSuccess: (state, action) => {
      if (state.menuList.data.length > 0) {
        state.menuList.data.push(action.payload);
      }
    },
  },
});

export default appMenuSlice.reducer;
export const appMenuActions = appMenuSlice.actions;

//OTHER ACTIONS
export const fetchAppMenuListAction = () => async (dispatch: any) => {
  dispatch(appMenuActions.fetchMenuRequest());
  try {
    const response = await Http.Get({
      path: `appmenus`,
      useAuth: true,
    });
    const result = await response.json();
    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    dispatch(appMenuActions.fetchMenuSuccess(result.data));
  } catch (error: any) {
    dispatch(appMenuActions.fetchMenuFailure(error.message));
  }
};

//add new parent menu
export const createNewAppMenuAction =
  ({ data }: { data: any }) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Post({
        path: `appmenus`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.success('Created');
      dispatch(
        appMenuActions.createNewMenuSuccess({ ...result, children: [] })
      );
    } catch (error: any) {
      toast.error(error.message);
      throw error;
    }
  };

//delete a menu
export const deleteAppMenuItemAction =
  ({ menuId }: { menuId: number }) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Delete({
        path: `appmenus/${menuId}`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      dispatch(fetchAppMenuListAction());
      toast.success('Deleted');
    } catch (error: any) {
      toast.error(error.message);
      throw error;
    }
  };

//update menu
export const updateAppMenuItemAction =
  ({ menuId, data }: { menuId: number; data: any }) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Put({
        path: `appmenus/${menuId}`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.success('Updated');
      dispatch(fetchAppMenuListAction());
    } catch (error: any) {
      toast.error(error.message);
      throw error;
    }
  };

export const rebuildAppMenuAction = (data: any) => async (dispatch: any) => {
  try {
    const response = await Http.Post({
      path: `appmenus/rebuild`,
      data,
      useAuth: true,
    });
    const result = await response.json();
    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    dispatch(fetchAppMenuListAction());
  } catch (error: any) {
    toast.error(error.message);
    throw error;
  }
};
