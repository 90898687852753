import { createSlice } from '@reduxjs/toolkit';
import type {
  SiteSetting,
  accrssControl,
  ShopAddress,
  redirection,
  chatControl,
} from './../models/setting';
import type Pagination from './../models/pagination';
import Http from './../helpers/http';
import { toast } from 'react-toastify';
import errorExtractor from '../utils/errorExtractor';

type SettingState = {
  settingList: {
    loading: boolean;
    data: SiteSetting[];
    error?: string;
  };
  storeAddressList: {
    loading: boolean;
    data: ShopAddress[];
    error?: string;
  };
  redirectionList: {
    loading: boolean;
    list: redirection[];
    meta?: Pagination;
    error?: string;
  };
  accrssControl: {
    loading: boolean;
    data?: accrssControl;
    error?: string;
  };
  chatControl: {
    loading: boolean;
    data?: chatControl;
    error?: string;
  };
};
const initialState: SettingState = {
  settingList: {
    loading: false,
    data: [],
  },
  storeAddressList: {
    loading: false,
    data: [],
  },
  redirectionList: {
    loading: false,
    list: [],
  },
  accrssControl: {
    loading: false,
  },
  chatControl: {
    loading: false,
  },
};

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    fetchSettingsRequest: (state) => {
      state.settingList.loading = true;
    },
    fetchSettingsSuccess: (state, action) => {
      state.settingList = {
        loading: false,
        data: action.payload,
      };
    },
    fetchSettingsFailure: (state, action) => {
      state.settingList.loading = false;
      state.settingList.error = action.payload;
    },
    //shop address->start
    fetchShopAddressListRequest: (state) => {
      state.storeAddressList.loading = true;
    },
    fetchShopAddressListSuccess: (state, action) => {
      state.storeAddressList = {
        loading: false,
        data: action.payload,
      };
    },
    fetchShopAddressListFailure: (state, action) => {
      state.storeAddressList.loading = true;
      state.storeAddressList.error = action.payload;
    },
    addNewAddress: (state, action) => {
      state.storeAddressList.data.push(action.payload);
    },
    deleteAddress: (state, action) => {
      state.storeAddressList.data = state.storeAddressList.data.filter(
        (address) => address.id !== action.payload
      );
    },
    //shop address->end
    //redirectionList
    fetchRedirectionListRequest: (state) => {
      state.redirectionList.loading = true;
    },
    fetchRedirectionListSuccess: (state, action) => {
      state.redirectionList = {
        loading: false,
        list: action.payload.list,
        meta: action.payload.meta,
      };
    },
    fetchRedirectionListFailure: (state, action) => {
      state.redirectionList.loading = true;
      state.redirectionList.error = action.payload;
    },
    fetchRedirectionPostSuccess: (state, action) => {
      state.redirectionList = {
        loading: false,
        list: [...state.redirectionList.list, action.payload],
        meta: state.redirectionList.meta,
      };
    },
    fetchRedirectionPutSuccess: (state, action) => {
      state.redirectionList = {
        loading: false,
        list: [
          ...state.redirectionList.list.filter(
            (list) => list.id !== action.payload.id
          ),
          action.payload,
        ],
        meta: state.redirectionList.meta,
      };
    },
    fetchRedirectionDeleteSuccess: (state, action) => {
      state.redirectionList = {
        loading: false,
        list: state.redirectionList.list.filter(
          (list) => list.id !== action.payload
        ),
        meta: state.redirectionList.meta,
      };
    },
    //Accrss Control
    fetchAccrssControlRequest: (state) => {
      state.accrssControl.loading = true;
    },
    fetchAccrssControlSuccess: (state, action) => {
      state.accrssControl = {
        loading: false,
        data: action.payload,
      };
    },
    fetchAccrssControlFailure: (state, action) => {
      state.accrssControl.loading = true;
      state.accrssControl.error = action.payload;
    },
    //Chat Control
    fetchChatControlRequest: (state) => {
      state.chatControl.loading = true;
    },
    fetchChatControlSuccess: (state, action) => {
      state.chatControl = {
        loading: false,
        data: action.payload,
      };
    },
    fetchChatControlFailure: (state, action) => {
      state.chatControl.loading = true;
      state.chatControl.error = action.payload;
    },
  },
});

export default settingSlice.reducer;
export const settingActions = settingSlice.actions;

//OTHER ACTIONS
export const fetchSettings = () => async (dispatch: any) => {
  dispatch(settingActions.fetchSettingsRequest());
  try {
    const res = await Http.Get({
      path: `settings`,
      useAuth: true,
    });
    const result = await res.json();

    if (!res.ok) {
      throw new Error('failed');
    }
    dispatch(settingActions.fetchSettingsSuccess(result));
  } catch (error: any) {
    dispatch(settingActions.fetchSettingsFailure(error.message));
  }
};

export const updateSettingAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await Http.Put({
      path: `settings`,
      data,
      useAuth: true,
    });
    const result = await res.json();

    if (!res.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    dispatch(settingActions.fetchSettingsSuccess(result));
    toast.success('Updated');
  } catch (error: any) {
    toast.error(error.message);
  }
};

//address
export const fetchAddressListAction = () => async (dispatch: any) => {
  dispatch(settingActions.fetchShopAddressListRequest());
  try {
    const res = await Http.Get({
      path: `address`,
      useAuth: true,
    });
    const result = await res.json();

    if (!res.ok) {
      throw new Error('failed');
    }
    dispatch(settingActions.fetchShopAddressListSuccess(result.data));
  } catch (error: any) {
    dispatch(settingActions.fetchShopAddressListFailure(error.message));
  }
};

export const addNewAddressAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await Http.Post({
      path: `address`,
      data,
      useAuth: true,
    });
    const result = await res.json();

    if (!res.ok) {
      throw new Error('failed');
    }
    dispatch(settingActions.addNewAddress(result));
  } catch (error: any) {
    throw error;
  }
};

export const updateAddressAction =
  (id: number, data: any) => async (dispatch: any) => {
    try {
      const res = await Http.Put({
        path: `address/${id}`,
        data,
        useAuth: true,
      });
      if (!res.ok) {
        throw new Error('failed');
      }
      dispatch(fetchAddressListAction());
      return await res.json();
    } catch (error: any) {
      throw error;
    }
  };

export const deleteAddressAction = (id: number) => async () => {
  try {
    const res = await Http.Delete({
      path: `address/${id}`,
      useAuth: true,
    });
    // const result = await res.json();

    if (!res.ok) {
      throw new Error('failed');
    }
  } catch (error: any) {
    throw error;
  }
};

//Redirection List
export const fetchRedirectionListAction =
  (page: number) => async (dispatch: any) => {
    dispatch(settingActions.fetchRedirectionListRequest());
    try {
      const res = await Http.Get({
        path: `url?page=${page}`,
        useAuth: true,
      });
      const result = await res.json();

      if (!res.ok) {
        throw new Error('failed');
      }
      dispatch(
        settingActions.fetchRedirectionListSuccess({
          list: result.data,
          meta: result?.links.length
            ? {
                current_page: result.current_page,
                first_page_url: result.first_page_url,
                from: result.from,
                last_page: result.last_page,
                last_page_url: result.last_page_url,
                links: result.links,
                next_page_url: result.next_page_url,
                path: result.path,
                per_page: result.per_page,
                prev_page_url: result.prev_page_url,
                to: result.to,
                total: result.total,
              }
            : undefined,
        })
      );
    } catch (error: any) {
      dispatch(settingActions.fetchRedirectionListFailure(error.message));
    }
  };
export const fetchRedirectionPostAction =
  (data: unknown) => async (dispatch: any) => {
    dispatch(settingActions.fetchRedirectionListRequest());
    try {
      const res = await Http.Post({
        path: `url`,
        data,
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('failed');
      }
      dispatch(settingActions.fetchRedirectionPostSuccess(result.data));
      return;
    } catch (error: any) {
      dispatch(settingActions.fetchRedirectionListFailure(error.message));
    }
  };
export const fetchRedirectionPutAction =
  (id: number, data: unknown) => async (dispatch: any) => {
    dispatch(settingActions.fetchRedirectionListRequest());
    try {
      const res = await Http.Put({
        path: `url/${id}`,
        data,
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('failed');
      }
      dispatch(settingActions.fetchRedirectionPutSuccess(result.data));
      return;
    } catch (error: any) {
      dispatch(settingActions.fetchRedirectionListFailure(error.message));
    }
  };
export const fetchRedirectionDeleteAction =
  (id: number) => async (dispatch: any) => {
    dispatch(settingActions.fetchRedirectionListRequest());
    try {
      const res = await Http.Delete({
        path: `url/${id}`,
        useAuth: true,
      });
      await res.json();
      if (!res.ok) {
        throw new Error('failed');
      }
      dispatch(settingActions.fetchRedirectionDeleteSuccess(id));
    } catch (error: any) {
      dispatch(settingActions.fetchRedirectionListFailure(error.message));
    }
  };
//Accrss Control
export const fetchAccrssControlAction = () => async (dispatch: any) => {
  dispatch(settingActions.fetchAccrssControlRequest());
  try {
    const res = await Http.Get({
      // path: `staffs/controll`,
      path: `staffs/access`,
      useAuth: true,
    });
    const result = await res.json();

    if (!res.ok) {
      throw new Error('failed');
    }
    dispatch(settingActions.fetchAccrssControlSuccess(result));
  } catch (error: any) {
    dispatch(settingActions.fetchAccrssControlFailure(error.message));
  }
};

export const fetchAccrssControlPostAction =
  (data: unknown) => async (dispatch: any) => {
    dispatch(settingActions.fetchAccrssControlRequest());
    try {
      const res = await Http.Post({
        path: `staffs/controll`,
        data,
        useAuth: true,
      });
      const result = await res.json();

      if (!res.ok) {
        throw new Error('failed');
      }
      dispatch(settingActions.fetchAccrssControlSuccess(result));
    } catch (error: any) {
      dispatch(settingActions.fetchAccrssControlFailure(error.message));
    }
  };

export const fetchChatControlAction = () => async (dispatch: any) => {
  dispatch(settingActions.fetchChatControlRequest());
  try {
    const res = await Http.Get({
      path: `chat_agent`,
      useAuth: true,
    });
    const result = await res.json();

    if (!res.ok) {
      throw new Error('failed');
    }
    dispatch(settingActions.fetchChatControlSuccess(result));
  } catch (error: any) {
    dispatch(settingActions.fetchChatControlFailure(error.message));
  }
};

export const updateChatControlAction = (data: any) => async (dispatch: any) => {
  dispatch(settingActions.fetchChatControlRequest());
  try {
    const response = await Http.Put({
      path: `chat_agent`,
      data,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      throw new Error('Failed');
    }
    dispatch(settingActions.fetchChatControlSuccess(result));
  } catch (error: any) {
    dispatch(settingActions.fetchChatControlFailure(error.message));
  }
};
