import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import errorExtractor from "../utils/errorExtractor";
import Http from "./../helpers/http";
import { type OfferlistItem } from './../models/offer';


type OfferState = {
    offerList: {
        loading: boolean,
        data: OfferlistItem[],
        error?: any
    }
};

const initialState: OfferState = {
    offerList: {
        loading: false,
        data: []
    }
};

const offerSlice = createSlice({
    name: "offer",
    initialState,
    reducers: {
        fetchAllOffersRequest: (state) => {
            state.offerList.loading = true;
        },
        fetchAllOffersSuccess: (state, action) => {
            state.offerList = {
                loading: false,
                data: action.payload
            }
        },
        fetchAllOffersFailure: (state, action) => {
            state.offerList.loading = false;
            state.offerList.error = action.payload;
        },
        createOfferSuccess: (state, action) => {
            if (state.offerList.data.length) {
                state.offerList.data.push(action.payload);
            }
        },
        updateOfferSuccess: (state, action) => {
            state.offerList.data = state.offerList.data.map(offer => offer.id === action.payload.id ? action.payload : offer);
        },
        deleteOfferSuccess: (state, action) => {
            state.offerList.data = state.offerList.data.filter(offer => offer.id !== action.payload);
        }
    }
});

export default offerSlice.reducer;

export const offerActions = offerSlice.actions;

//OTHER ACTIONS
export const fetchAllOffersAction = () => async (dispatch: any) => {
    dispatch(offerActions.fetchAllOffersRequest());
    try {
        const response = await Http.Get({ path: `offers`, useAuth: true });
        const result = await response.json();

        if (!response.ok) {
            throw new Error("Failed");
        }
        dispatch(offerActions.fetchAllOffersSuccess(result));

    } catch (error: any) {
        dispatch(offerActions.fetchAllOffersFailure(error.message));
    }
};

export const addNewOfferAction = (data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Post({ path: `offers`, data, useAuth: true });
        const result = await response.json();

        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }

        dispatch(offerActions.createOfferSuccess(result));
        toast.success("Created");

    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}
export const updateOfferAction = (offerId: number, data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Put({ path: `offers/${offerId}`, data, useAuth: true });
        const result = await response.json();

        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }

        toast.success("Updated");
        dispatch(offerActions.updateOfferSuccess(result));

    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}
export const deleteOfferAction = (offerId: number) => async (dispatch: any) => {
    try {
        const response = await Http.Delete({ path: `offers/${offerId}`, useAuth: true });
        if (!response.ok) {
            throw new Error("Failed to delete offer");
        }

        toast.success("Deleted");
        dispatch(offerActions.deleteOfferSuccess(offerId));

    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}