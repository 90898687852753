import { createSlice } from '@reduxjs/toolkit';
import type {
  OrderDetailsType,
  Order,
  PendingOrder,
  courierListType,
} from './../models/order';
import type Pagination from './../models/pagination';
import { type OrderStatus } from './../models/order';
import { toast } from 'react-toastify';
import Http from './../helpers/http';
import errorExtractor from '../utils/errorExtractor';

type OrderState = {
  orderList: {
    loading: boolean;
    data: Order[];
    error?: string | null;
    meta?: Pagination;
    isSearchResult?: boolean;
  };
  pendingOrderList: {
    loading: boolean;
    data: PendingOrder[];
    error?: string | null;
    meta?: Pagination;
    isSearchResult?: boolean;
  };
  orderStatusList: {
    id: OrderStatus;
    title: string;
  }[];
  order: {
    loading: boolean;
    data?: OrderDetailsType;
    error?: string | null;
  };
  courierList: {
    loading: boolean;
    data: courierListType[];
    error?: string;
  };
  trakingList: {
    data: Readonly<any>;
  };
};

const initialState: OrderState = {
  orderList: {
    loading: false,
    data: [],
  },
  pendingOrderList: {
    loading: false,
    data: [],
    isSearchResult: false,
  },
  orderStatusList: [
    {
      id: 'payment-pending',
      title: 'Payment Pending',
    },
    {
      id: 'payment-received',
      title: 'Payment Received',
    },
    {
      id: 'cancelled',
      title: 'Cancelled',
    },
  ],
  order: {
    loading: false,
  },
  courierList: {
    loading: false,
    data: [],
  },
  trakingList: {
    data: [],
  },
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    fetchOrderListRequest: (state) => {
      state.orderList.loading = true;
    },
    fetchOrderListSuccess: (state, action) => {
      state.orderList = {
        loading: false,
        data: action.payload.data,
        error: null,
        meta: action.payload.meta,
        isSearchResult: action.payload.isSearchResult,
      };
    },
    fetchOrderListFailure: (state, action) => {
      state.orderList.loading = false;
      state.orderList.error = action.payload;
    },
    //Pending ORDER SINGLE
    fetchPendingOrderListRequest: (state) => {
      state.pendingOrderList.loading = true;
    },
    fetchPendingOrderListSuccess: (state, action) => {
      state.pendingOrderList = {
        loading: false,
        data: action.payload.data,
        error: null,
        meta: action.payload.meta,
        isSearchResult: action.payload.isSearchResult,
      };
    },
    fetchPendingOrderListFailure: (state, action) => {
      state.pendingOrderList.loading = false;
      state.pendingOrderList.error = action.payload;
    },
    //ORDER SINGLE
    fetchOrderSingleRequest: (state) => {
      state.order.loading = true;
    },
    fetchOrderSingleSuccess: (state, action) => {
      state.order = {
        loading: false,
        data: action.payload.data,
        error: null,
      };
    },
    fetchOrderSingleFailure: (state, action) => {
      state.order.loading = false;
      state.order.error = action.payload;
    },
    //Courier List
    fetchCourierListRequest: (state) => {
      state.courierList.loading = true;
    },
    fetchCourierListSuccess: (state, action) => {
      state.courierList = {
        loading: false,
        data: action.payload,
      };
    },
    fetchCourierListFailure: (state, action) => {
      state.courierList.loading = false;
      state.courierList.error = action.payload;
    },
    //UPDATE Assigned STATUS
    updateAssignedStatusSuccess: (state, action) => {
      state.orderList.data = state.orderList.data.map((order) => {
        if (order.id === action.payload.id) {
          return {
            ...order,
            assign_to: action.payload.assign_to,
            supervised_by: action.payload.supervised_by,
          };
        } else {
          return { ...order };
        }
      });
    },
    //UPDATE ORDER STATUS
    updateOrderCommentSingleSuccess: (state, action) => {
      state.order.data?.comments.push(action.payload.data);
      state.order.data!.status = action.payload.data.status;
      state.order.data!.sub_status = action.payload.data.sub_status;
    },
    //UPDATE ORDER STATUS
    updateOrderSingleStatusSuccess: (state, action) => {
      state.order.data!.assign_to = action.payload.assign_to
        ? action.payload.assign_to
        : state.order.data!.assign_to;
      state.order.data!.supervised_by = action.payload.supervised_by
        ? action.payload.supervised_by
        : state.order.data!.supervised_by;
      state.order.data!.staffs = action.payload.staffs;
    },
    //UPDATE Courier STATUS
    updateCourierStatusSuccess: (state, action) => {
      state.trakingList.data = action.payload.data;
      if (action.payload.type === 'update') {
        state.order.data!.courier_id = action.payload.courier_id;
        // state.order.data!.courier_code = action.payload.courier_code;
        state.order.data!.shipping_paid = action.payload.shipping_paid;
        state.order.data!.shipping_total.value = action.payload.shipping_total;
      }
    },
  },
});

export default orderSlice.reducer;
export const orderActions = orderSlice.actions;

//ACTIONS
export const fetchOrderListAction =
  (pageNo: number, pageSize: number) => async (dispatch: any) => {
    dispatch(orderActions.fetchOrderListRequest());
    try {
      const response = await Http.Get({
        path: `orders?page=${pageNo}&limit=${pageSize}`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      dispatch(
        orderActions.fetchOrderListSuccess({
          data: result.data,
          meta: result.meta,
          isSearchResult: false,
        })
      );
    } catch (error: any) {
      dispatch(orderActions.fetchOrderListFailure(error.message));
    }
  };
// create order
export const createNewOrderAction = (data: any) => async (dispatch: any) => {
  try {
    const response = await Http.Post({ path: `orders`, data, useAuth: true });
    const result = await response.json();
    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    toast.success('Order Created successfully');
    return result;
  } catch (error: any) {
    toast.error(error.message);
  }
};
// update order
export const updateOrderAction =
  (orderId: number, data: any, refreshPage?: boolean) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Put({
        path: `orders/${orderId}`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.success('Order updated successfully');
      if (refreshPage) {
        dispatch(fetchOrderSingleAction(orderId));
      }
      return result;
    } catch (error: any) {
      toast.error(error.message);
    }
  };
// search update order
export const searchOrderAction =
  (data: any, pageNo?: number, pageSize?: number) => async (dispatch: any) => {
    dispatch(orderActions.fetchOrderListRequest());
    try {
      const res = await Http.Post({
        path: `search/orders?page=${pageNo}&limit=${pageSize}`,
        data: data ? data : {},
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Product fetching failed');
      }
      dispatch(
        orderActions.fetchOrderListSuccess({
          data: result.data,
          meta: result.meta,
          isSearchResult: true,
        })
      );
    } catch (error: any) {
      dispatch(orderActions.fetchOrderListFailure(error.message));
    }
  };
// Pending product
export const fetchPendingOrderListAction =
  ({ data, pageNo }: { data?: any; pageNo: number }) =>
  async (dispatch: any) => {
    dispatch(orderActions.fetchPendingOrderListRequest());
    try {
      const response = await Http.Post({
        path: `order/list/line/pending_product?page=${pageNo}`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      dispatch(
        orderActions.fetchPendingOrderListSuccess({
          data: result.data,
          meta: result.meta,
          isSearchResult: false,
        })
      );
    } catch (error: any) {
      dispatch(orderActions.fetchPendingOrderListFailure(error.message));
    }
  };
// Get single order
export const fetchOrderSingleAction =
  (orderId: number) => async (dispatch: any) => {
    dispatch(orderActions.fetchOrderSingleRequest());
    try {
      const response = await Http.Get({
        path: `orders/${orderId}`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      dispatch(
        orderActions.fetchOrderSingleSuccess({
          data: result,
        })
      );
      return result;
    } catch (error: any) {
      dispatch(orderActions.fetchOrderSingleFailure(error.message));
    }
  };
// Get courier list
export const fetchCourierListAction = () => async (dispatch: any) => {
  dispatch(orderActions.fetchCourierListRequest());
  try {
    const res = await Http.Get({
      path: `order/couriers`,
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed to fetch couriers list');
    }
    dispatch(orderActions.fetchCourierListSuccess(result));
  } catch (error: any) {
    dispatch(orderActions.fetchCourierListFailure(error.message));
  }
};
//Courier
export const updateCourierListAction =
  (orderId: number, type: 'get' | 'update' = 'get', data: any) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Put({
        path: `order/couriers/${orderId}`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      if (type === 'update') {
        toast.success('Courier charges updated successfully');
      }
      dispatch(
        orderActions.updateCourierStatusSuccess({
          data: result,
          type,
          courier_id: data.courier_id,
          // courier_code: data.courier_code,
          shipping_total: data.shipping_total,
          shipping_paid: data.shipping_paid,
        })
      );
    } catch (error: any) {
      toast.error(error.message);
    }
  };
//Add Paymen Update
export const AddOrderPaymenAction =
  (orderId: number, data: any) => async (dispatch: any) => {
    try {
      const res = await Http.Post({
        path: `order/${orderId}/payment`,
        data,
        useAuth: true,
      });
      if (!res.ok) {
        throw new Error('Failed to get payment-list');
      }
      // const result = await res.json();
      dispatch(fetchOrderSingleAction(orderId));
    } catch (error: any) {
      console.error(error);
      //dispatch(paymentActions.fetchOrderPaymenListFailure(error.message));
    }
  };
//Order Paymen Update
export const UpdateOrderPaymenAction =
  (orderId: number, paymenId: number, data: any) => async (dispatch: any) => {
    try {
      const res = await Http.Put({
        path: `order/${orderId}/payment/${paymenId}`,
        data,
        useAuth: true,
      });
      // const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed to get payment-list');
      }
      // console.log(result);
      //dispatch(paymentActions.fetchUpdateOrderPaymenListSuccess(result));
      dispatch(fetchOrderSingleAction(orderId));
    } catch (error: any) {
      console.error(error);
      //dispatch(paymentActions.fetchOrderPaymenListFailure(error.message));
    }
  };
//Product Source Update
export const updateProductLineAction =
  (orderId: number, lineId: number, data?: any) => async (dispatch: any) => {
    try {
      const response = await Http.Put({
        path: `order/${orderId}/line/${lineId}`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      dispatch(fetchOrderSingleAction(orderId));
      toast.success(
        `Product ${data?.product_code ? 'Code' : 'Source'} updated successfully`
      );
      return result;
    } catch (error: any) {
      toast.error(error.message);
    }
  };
// Assigned Status Update Action
export const updateAssignedStatusAction =
  (orderId: number, data?: any, singlePage?: boolean) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Put({
        path: `orders/assign/${orderId}`,
        data,
        useAuth: true,
      });

      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      if (!data.assign_to) {
        toast.success('Shipping options updated successfully');
        return;
      }
      if (!singlePage) {
        dispatch(
          orderActions.updateAssignedStatusSuccess({
            id: orderId,
            assign_to: data.assign_to,
            supervised_by: data.supervised_by,
          })
        );
      } else {
        dispatch(
          orderActions.updateOrderSingleStatusSuccess({
            id: orderId,
            assign_to: data.assign_to,
            supervised_by: data.supervised_by,
            staffs: result?.staffs,
          })
        );
      }
      toast.success('New assigned person selected');
    } catch (error: any) {
      toast.error(error.message);
    }
  };
// order comments
export const postOrderCommentAction =
  (data: { order_id: number; details: string; staff_id: number }) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Post({
        path: `orders/comments`,
        data,
        useAuth: true,
      });
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      const result = await response.json();
      dispatch(
        orderActions.updateOrderCommentSingleSuccess({
          data: result,
        })
      );
    } catch (error: any) {
      dispatch(orderActions.fetchOrderSingleFailure(error.message));
    }
  };
// order comments
export const billingStatusAction =
  (data: { billing_status: 0 | 1 }) => async (dispatch: any) => {
    try {
      const response = await Http.Post({
        path: `orders/comments`,
        data,
        useAuth: true,
      });
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      const result = await response.json();
      dispatch(
        orderActions.updateOrderCommentSingleSuccess({
          data: result,
        })
      );
    } catch (error: any) {
      dispatch(orderActions.fetchOrderSingleFailure(error.message));
    }
  };

//Product Source Update
export const updateProductSource = (data?: any) => async () => {
  try {
    const response = await Http.Post({
      path: `order/list/line/sourceStatus`,
      data,
      useAuth: true,
    });
    const result = await response.json();
    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    return result;
  } catch (error: any) {
    toast.error(error.message);
  }
};
