import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Http from './../helpers/http';
import { Attribute, AttributeItem } from '../models/spec';

type AttributeState = {
  attributeList: {
    loading: boolean;
    data: Attribute[];
    error?: string | null;
  };
};

const initialState: AttributeState = {
  attributeList: {
    loading: false,
    data: [],
  },
};

const attributeSlice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {
    fetchAttributeRequest: (state) => {
      state.attributeList.loading = true;
    },
    fetchAttributeSuccess: (state, action) => {
      state.attributeList = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    fetchAttributeFailure: (state, action) => {
      state.attributeList.loading = false;
      state.attributeList.error = action.payload;
    },
    updateAttributeName: (state, action) => {
      state.attributeList.data = state.attributeList.data.map((attr) =>
        attr.id === action.payload.id
          ? { ...attr, name: action.payload.name }
          : attr
      );
    },
    //add new attribute
    addNewAttributeSuccess: (state, action) => {
      state.attributeList.data.push(action.payload);
    },
    //add new attribute value
    addNewAttributeValueSuccess: (
      state,
      action: { type: string; payload: { attrId: number; attr: AttributeItem } }
    ) => {
      const targetIndex = state.attributeList.data.findIndex(
        (attr) => attr.id === action.payload.attrId
      );
      if (targetIndex >= 0) {
        state.attributeList.data[targetIndex].values.push(action.payload.attr);
      }
    },
  },
});

export default attributeSlice.reducer;
export const attributeActions = attributeSlice.actions;

//OTHER ACTIONS
export const fetchAttributeAction = () => async (dispatch: any) => {
  dispatch(attributeActions.fetchAttributeRequest());
  try {
    const response = await Http.Get({ path: `product-options`, useAuth: true });

    if (!response.ok) {
      throw new Error('Fail to get attributes');
    }
    const result = await response.json();
    dispatch(attributeActions.fetchAttributeSuccess(result.data));
  } catch (error: any) {
    dispatch(attributeActions.fetchAttributeFailure(error.message));
  }
};

export const addNewAttributeAction =
  (data: { name: string }) => async (dispatch: any) => {
    try {
      const response = await Http.Post({
        path: `product-options`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        console.error(result);
        throw new Error('Fail to create new attribute');
      }
      dispatch(
        attributeActions.addNewAttributeSuccess({ ...result, values: [] })
      );
    } catch (error: any) {
      toast.error(error.message);
    }
  };

export const addNewAttributeValueAction =
  (data: { name: string }, attrId: number) => async (dispatch: any) => {
    try {
      const response = await Http.Post({
        path: `product-options/${attrId}/values`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        console.log(result);
        throw new Error('Failed');
      }

      dispatch(
        attributeActions.addNewAttributeValueSuccess({ attrId, attr: result })
      );
    } catch (error: any) {
      toast.error(error.message);
    }
  };

export const updateAttributeValueAction =
  (data: { name: string }, attrId: number, valId: number) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Put({
        path: `product-options/${attrId}/values/${valId}`,
        data,
        useAuth: true,
      });
      if (response.ok) {
        dispatch(fetchAttributeAction());
      }
      toast.success('Updated');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

export const deleteAttribureValueAction =
  (attrId: number, attrValId: number) => async (dispatch: any) => {
    const toastId = toast.loading('Deleting attribute value...');
    try {
      const response = await Http.Delete({
        path: `product-options/${attrId}/values/${attrValId}`,
        useAuth: true,
      });

      // const result = await response.json();
      if (!response.ok) {
        throw new Error('Fail to delete attribute value');
      }

      toast.update(toastId, {
        render: 'Deleted',
        type: 'success',
        isLoading: false,
        autoClose: 4000,
      });
      dispatch(fetchAttributeAction());
    } catch (error: any) {
      toast.update(toastId, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 4000,
      });
    }
  };
