import { createSlice } from '@reduxjs/toolkit';
import { type Me } from './../models/auth';
import Http from '../helpers/http';
import { removeToken } from './../helpers/token';
import { toast } from 'react-toastify';

type AuthState = {
  me: {
    data?: Me | null;
  };
};

const initialState: AuthState = {
  me: {
    data: null,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateMe: (state, action) => {
      state.me.data = action.payload;
    },
  },
});

export default authSlice.reducer;
export const authActions = authSlice.actions;

export const authMeAction = () => async (dispatch: any) => {
  try {
    const res = await Http.Get({ path: 'auth/me', useAuth: true });
    const result = await res.json();
    if (res.ok) {
      dispatch(authActions.updateMe(result));
    }
  } catch (error) {
    // console.log(error);
  }
};

export const logoutAction = () => () => {
  Http.Post({ path: 'auth/logout', data: {}, useAuth: true })
    .then(() => {
      removeToken();
      toast.success('Lgout success');
      setTimeout(() => {
        window.location.reload();
      }, 500);
    })
    .catch(() => toast.error('Logut failed!'));
};
