import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import errorExtractor from '../utils/errorExtractor';
import Http from './../helpers/http';
import type { Order, OrderDetailsType } from '../models/order';
import type Pagination from './../models/pagination';

type BillState = {
  billList: {
    loading: boolean;
    list: Order[];
    error?: string | null;
    meta?: Pagination;
    isFiltered?: boolean;
  };
  bill: {
    loading: boolean;
    data?: OrderDetailsType;
    error?: string | null;
  };
  trakingList: {
    data: Readonly<any>;
  };
};

const initialState: BillState = {
  billList: {
    loading: false,
    list: [],
  },
  bill: {
    loading: false,
  },
  trakingList: {
    data: [],
  },
};

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    //Bill LIST
    fetchBillListRequest: (state) => {
      state.billList.loading = true;
    },
    fetchBillListSuccess: (state, action) => {
      state.billList = {
        loading: false,
        list: action.payload.data,
        meta: action.payload.meta,
        isFiltered: action.payload.isFiltered,
        error: null,
      };
    },
    fetchBillListFailure: (state, action) => {
      state.billList.loading = false;
      state.billList.error = action.payload;
    },
    //Bill SINGLE
    fetchBillSingleRequest: (state) => {
      state.bill.loading = true;
    },
    fetchBillSingleSuccess: (state, action) => {
      state.bill = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    fetchBillSingleFailure: (state, action) => {
      state.bill.loading = false;
      state.bill.error = action.payload;
    },

    //Bill Status Update
    fetchBillStatusSuccess: (state, action) => {
      state.billList = {
        ...state.billList,
        list: state.billList.list.map((obj) => {
          if (obj.id === action.payload.id) {
            return { ...obj, lines: action.payload.lines };
          }
          return obj;
        }),
      };
    },
    //UPDATE Courier STATUS
    updateCourierStatusSuccess: (state, action) => {
      state.trakingList.data = action.payload.data;
      if (action.payload.type === 'update') {
        state.bill.data!.courier_id = action.payload.courier_id;
        state.bill.data!.courier_code = action.payload.courier_code;
      }
    },
    //UPDATE Shipping Total STATUS
    updateShippingStatusSuccess: (state, action) => {
      state.bill.data!.total = action.payload.total;
      state.bill.data!.shipping_total = action.payload.shipping_total;
      state.bill.data!.shipping_paid = action.payload.shipping_paid
        ? Number(action.payload.shipping_paid)
        : 0;
    },
    //UPDATE ORDER STATUS
    updateOrderCommentSingleSuccess: (state, action) => {
      state.bill.data?.comments.push(action.payload.data);
      state.bill.data!.status = action.payload.data.status;
      state.bill.data!.sub_status = action.payload.data.sub_status;
    },
  },
});

export default billingSlice.reducer;
export const billingActions = billingSlice.actions;

// bill list
export const fetchBillListAction =
  (pageNo: number, pageSize: number) => async (dispatch: any) => {
    dispatch(billingActions.fetchBillListRequest());
    try {
      const response = await Http.Get({
        path: `bill?page=${pageNo}&limit=${pageSize}`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      dispatch(
        billingActions.fetchBillListSuccess({
          data: result.data,
          meta: result.meta,
          isSearchResult: false,
        })
      );
    } catch (error: any) {
      toast.error(error.message);
      dispatch(billingActions.fetchBillListFailure(error.message));
    }
  };
// bill single page
export const fetchBillSingleAction =
  (billId: number) => async (dispatch: any) => {
    dispatch(billingActions.fetchBillSingleRequest());
    try {
      const response = await Http.Get({
        path: `orders/${billId}`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      dispatch(billingActions.fetchBillSingleSuccess(result));
    } catch (error: any) {
      dispatch(billingActions.fetchBillSingleFailure(error.message));
    }
  };
// search update order
export const searchBillAction =
  ({
    data,
    pageNo,
    pageSize,
    isFiltered,
  }: {
    data: any;
    pageNo?: number;
    pageSize?: number;
    isFiltered?: boolean;
  }) =>
  async (dispatch: any) => {
    dispatch(billingActions.fetchBillListRequest());
    try {
      const res = await Http.Post({
        path: `search/bill?page=${pageNo}&limit=${pageSize}`,
        data: {
          ...data,
          paginate: 1,
        },
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      dispatch(
        billingActions.fetchBillListSuccess({
          data: result.data,
          meta: result.meta,
          isFiltered,
        })
      );
    } catch (error: any) {
      dispatch(billingActions.fetchBillListFailure(error.message));
    }
  };
// Assigned Status Update Action
export const updateShippingAction =
  (billId: number, data?: any) => async (dispatch: any) => {
    try {
      const response = await Http.Put({
        path: `orders/assign/${billId}`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      dispatch(billingActions.updateShippingStatusSuccess(result));
      toast.success('Shipping options updated successfully');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

//Product Source Update
export const updateProductStausAction =
  ({
    lineId,
    data,
    singlePage = false,
  }: {
    lineId: number;
    data?: any;
    singlePage?: boolean;
  }) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Put({
        path: `bill/${lineId}`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      //issue
      // console.log(result);
      if (singlePage) {
        dispatch(billingActions.fetchBillSingleSuccess(result));
      } else {
        dispatch(billingActions.fetchBillStatusSuccess(result));
      }
      // dispatch(fetchBillListAction(1));
      toast.success(`Product updated successfully`);
      return result;
    } catch (error: any) {
      toast.error(error.message);
    }
  };
//Order Status Update
export const updateCourierListAction =
  (orderId: number, type: 'get' | 'update' = 'get', data: any) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Put({
        path: `order/couriers/${orderId}`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      if (type === 'update') {
        toast.success('Couriers status updated successfully');
      }
      dispatch(
        billingActions.updateCourierStatusSuccess({
          data: result,
          type,
          courier_id: data.courier_id,
          courier_code: data.courier_code,
        })
      );
    } catch (error: any) {
      toast.error(error.message);
    }
  };

// order comments
export const postOrderCommentAction =
  (data: { order_id: number; details: string; staff_id: number }) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Post({
        path: `orders/comments`,
        data,
        useAuth: true,
      });
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      const result = await response.json();
      dispatch(
        billingActions.updateOrderCommentSingleSuccess({
          data: result,
        })
      );
    } catch (error: any) {
      dispatch(billingActions.fetchBillSingleFailure(error.message));
    }
  };

//Order Status Update
export const updateCourierStatusAction =
  ({ orderId, data }: { orderId: number; data: any }) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Put({
        path: `bill/edit/${orderId}`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.success('Order Priority Successfully Updated');
      // console.log(result);
    } catch (error: any) {
      toast.error(error.message);
    }
  };
