import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Http from '../helpers/http';
import { type PreOrder } from './../models/order';
import type Pagination from './../models/pagination';

type PreOrderState = {
  preOrderList: {
    loading: boolean;
    data: Array<PreOrder>;
    error?: string | null;
    meta?: Pagination;
    isSearchResult?: boolean;
  };
};

const initialState: PreOrderState = {
  preOrderList: {
    loading: false,
    data: [],
    isSearchResult: false,
  },
};

const preOrderSlice = createSlice({
  name: 'pre-order',
  initialState,
  reducers: {
    fetchPreOrderListRequest: (state) => {
      state.preOrderList.loading = true;
    },
    fetchPreOrderListSuccess: (state, action) => {
      state.preOrderList = {
        loading: false,
        data: action.payload.data,
        error: null,
        meta: action.payload.meta,
        isSearchResult: action.payload.isSearchResult,
      };
    },
    //UPDATE ORDER STATUS
    updatePreOrderList: (state, action) => {
      state.preOrderList.data = state.preOrderList.data.map((order) => {
        if (order.id === action.payload.id) {
          return {
            ...order,
            status: action.payload.status,
            assign_to: action.payload.assign_to,
          };
        } else {
          return { ...order };
        }
      });
    },
    fetchPreOrderListFailure: (state, action) => {
      state.preOrderList.loading = false;
      state.preOrderList.error = action.payload;
    },
    removePreOrderItem: (state, action) => {
      state.preOrderList.data = state.preOrderList.data.filter((order) => order.id !== action.payload);
    },
  },
});

export default preOrderSlice.reducer;
export const preOrderActions = preOrderSlice.actions;

export const fetchPreOrderListAction = (pageNo: number) => async (dispatch: any) => {
  dispatch(preOrderActions.fetchPreOrderListRequest());
  try {
    const response = await Http.Get({ path: `pre-orders?page=${pageNo}`, useAuth: true });
    const result = await response.json();
    if (!response.ok) {
      throw new Error('Order fetching failed');
    }
    dispatch(
      preOrderActions.fetchPreOrderListSuccess({
        data: result.data,
        meta: result.meta,
        isSearchResult: false,
      })
    );
  } catch (error: any) {
    dispatch(preOrderActions.fetchPreOrderListFailure(error.message));
  }
};
export const updatePreOrderAction = (orderId: number, data: any) => async (dispatch: any) => {
  try {
    const response = await Http.Put({ path: `pre-orders/${orderId}`, data, useAuth: true });

    if (!response.ok) {
      throw new Error('Update failed');
    }

    const result = await response.json();
    dispatch(preOrderActions.updatePreOrderList({ id: orderId, status: result.status, assign_to: result.assign_to }));
    toast.success('Status updated');
  } catch (error: any) {
    toast.error(error.message);
  }
};
export const deletePreOrderAction = (orderId: number) => async (dispatch: any) => {
  try {
    const response = await Http.Delete({ path: `pre-orders/${orderId}`, useAuth: true });
    if (!response.ok) {
      throw new Error('Failed');
    }
    dispatch(preOrderActions.removePreOrderItem(orderId));
  } catch (error: any) {
    toast.error(error.message);
  }
};
export const searchPreOrderAction = (data: any, page?: number) => async (dispatch: any) => {
  dispatch(preOrderActions.fetchPreOrderListRequest());
  try {
    const res = await Http.Post({
      path: `pre-orders/search${page ? `?page=${page}` : ''}`,
      data: {
        ...data,
        paginate: 1,
      },
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Product fetching failed');
    }
    dispatch(
      preOrderActions.fetchPreOrderListSuccess({
        data: result.data,
        meta: result.meta,
        isSearchResult: true,
      })
    );
  } catch (error: any) {
    dispatch(preOrderActions.fetchPreOrderListFailure(error.message));
  }
};
