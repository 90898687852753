import { createSlice } from '@reduxjs/toolkit';
import Http from './../helpers/http';
import { toast } from 'react-toastify';
import errorExtractor from '../utils/errorExtractor';
import type { CareerType, fieldsType } from '../models/career';

// Define the initial type
type AttributeState = {
  careerList: {
    loading: boolean;
    data: CareerType[];
    error: unknown | null;
  };
  careerSingle: {
    loading: boolean;
    data?: CareerType;
    error: unknown | null;
  };
  careerQuestion: {
    loading: boolean;
    data: fieldsType[];
    error: unknown | null;
  };
  addCareer: {
    loading: boolean;
    newlyCreated?: number | null;
    error: unknown | null;
  };
};
// Define the initial state using that type
const initialState: AttributeState = {
  careerList: {
    loading: false,
    data: [],
    error: null,
  },
  careerQuestion: {
    loading: false,
    data: [],
    error: null,
  },
  careerSingle: {
    loading: false,
    error: null,
  },
  addCareer: {
    loading: false,
    error: null,
  },
};

export const careerSlice = createSlice({
  name: 'career',
  initialState,
  reducers: {
    careerFetchLoading: (state) => {
      state.careerList.loading = true;
    },
    careerFetchSuccess: (state, action) => {
      state.careerList.loading = false;
      state.careerList.data = action.payload;
    },
    careerFetchFailure: (state, action) => {
      state.careerList.loading = false;
      state.careerList.error = action.payload;
    },
    //Career Single
    addCareerSingleLoading: (state) => {
      state.careerSingle.loading = true;
    },
    addCareerSingleSuccess: (state, action) => {
      state.careerSingle.loading = false;
      state.careerSingle.data = action.payload;
    },
    addCareerSingleFailure: (state, action) => {
      state.careerSingle.loading = false;
      state.careerSingle.error = action.payload;
    },
    //Career Question
    addCareerQuestionLoading: (state) => {
      state.careerQuestion.loading = true;
    },
    addCareerQuestionSuccess: (state, action) => {
      state.careerQuestion.loading = false;
      state.careerQuestion.data = action.payload;
    },
    addCareerQuestionFailure: (state, action) => {
      state.careerQuestion.loading = false;
      state.careerQuestion.error = action.payload;
    },
    //ADD Career
    addCareerLoading: (state) => {
      state.addCareer.loading = true;
    },
    addCareerSuccess: (state, action) => {
      state.addCareer.loading = false;
      state.addCareer.newlyCreated = action.payload;
      state.addCareer.error = null;
    },
    addCareerFailure: (state, action) => {
      state.addCareer.loading = false;
      state.addCareer.error = action.payload;
    },
    clearNewlyCreated: (state) => {
      state.addCareer.newlyCreated = undefined;
    },
  },
});

export const careerActions = careerSlice.actions;

export default careerSlice.reducer;

//other actions
export const fetchCareerAction = () => {
  return async (dispatch: any) => {
    dispatch(careerActions.careerFetchLoading());
    try {
      const response = await Http.Get({
        path: `career`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      dispatch(careerActions.careerFetchSuccess(result));
    } catch (error: any) {
      dispatch(careerActions.careerFetchFailure(error.message));
    }
  };
};
export const fetchSingleCareerAction = (jobId: number) => {
  return async (dispatch: any) => {
    dispatch(careerActions.addCareerSingleLoading());
    try {
      const response = await Http.Get({
        path: `career/${jobId}`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      dispatch(careerActions.addCareerSingleSuccess(result));
    } catch (error: any) {
      dispatch(careerActions.addCareerSingleFailure(error.message));
    }
  };
};
export const fetchCareerQuestionAction = (jobId: number) => {
  return async (dispatch: any) => {
    dispatch(careerActions.addCareerQuestionLoading());
    try {
      const response = await Http.Get({
        path: `career/${jobId}/field`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      dispatch(careerActions.addCareerQuestionSuccess(result));
    } catch (error: any) {
      dispatch(careerActions.addCareerQuestionFailure(error.message));
    }
  };
};
//DELETE career
export const deleteCareerAction = (CareerId: number) => async (dispatch: any) => {
  const toastId = toast.loading('Delete career...');
  try {
    const response = await Http.Delete({
      path: `career/${CareerId}`,
      useAuth: true,
    });
    const result = await response.json();
    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    toast.update(toastId, {
      render: 'Deleted',
      type: 'success',
      isLoading: false,
      autoClose: 4000,
    });
    dispatch(fetchCareerAction());
  } catch (error: any) {
    toast.update(toastId, {
      render: error.message,
      type: 'error',
      isLoading: false,
      autoClose: 4000,
    });
  }
};

//ADD NEW career
export const addCareerAction = (data: any) => async (dispatch: any) => {
  dispatch(careerActions.addCareerLoading());
  try {
    const response = await Http.Post({
      path: `career`,
      data,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    dispatch(careerActions.addCareerSuccess(result?.id));
  } catch (error: any) {
    dispatch(careerActions.addCareerFailure({ msg: error.message }));
    toast.error(error.message);
    throw error;
  }
};

//UPDATE career
export const updateCareerAction = (jobId: number, data: any) => async (dispatch: any) => {
  const toastId = toast.loading('Updating career...');
  try {
    const response = await Http.Put({
      path: `career/${jobId}`,
      data,
      useAuth: true,
    });
    const result = await response.json();
    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    dispatch(fetchSingleCareerAction(jobId));
    toast.update(toastId, {
      render: 'career updated',
      type: 'success',
      isLoading: false,
      autoClose: 4000,
    });
  } catch (error: any) {
    toast.update(toastId, {
      render: error.message,
      type: 'error',
      isLoading: false,
      autoClose: 4000,
    });
  }
};
