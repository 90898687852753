import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import errorExtractor from '../utils/errorExtractor';
import Http from './../helpers/http';
import type { Clip } from '../models/clip';
import type Pagination from './../models/pagination';

type MenuState = {
  clipList: {
    loading: boolean;
    data: Clip[];
    pagination?: Pagination;
    error?: any;
  };
  clip: {
    loading: boolean;
    data?: Clip;
    error?: any;
  };
};

const initialState: MenuState = {
  clipList: {
    loading: false,
    data: [],
  },
  clip: {
    loading: false,
  },
};

const appClipSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    fetchClipRequest: (state) => {
      state.clipList.loading = true;
    },
    fetchClipSuccess: (state, action) => {
      state.clipList = {
        loading: false,
        data: action.payload.data,
        pagination: action.payload.meta,
      };
    },
    fetchClipFailure: (state, action) => {
      state.clipList.loading = false;
      state.clipList.error = action.payload;
    },

    fetchClipSingleRequest: (state) => {
      state.clip.loading = true;
    },
    fetchClipSingleSuccess: (state, action) => {
      state.clip = {
        loading: false,
        data: action.payload,
      };
    },
    fetchClipSingleFailure: (state, action) => {
      state.clip.loading = false;
      state.clip.error = action.payload;
    },
    //update slider
    updateItemSuccess: (state, action) => {
      state.clipList.data = state.clipList.data.map((slider) =>
        slider.id === action.payload.id ? action.payload : slider
      );
    },
    //delete a slider
    deleteItemSuccess: (state, action) => {
      state.clipList.data = state.clipList.data.filter(
        (slider) => slider.id !== action.payload
      );
    },
  },
});

export default appClipSlice.reducer;
export const appClipActions = appClipSlice.actions;

//OTHER ACTIONS
//GET ALL Clip
export const fetchClipListAction = (page: number) => async (dispatch: any) => {
  dispatch(appClipActions.fetchClipRequest());

  try {
    const response = await Http.Get({
      path: `clips?page=${page}`,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    dispatch(appClipActions.fetchClipSuccess(result));
  } catch (error: any) {
    dispatch(appClipActions.fetchClipFailure(error.message));
  }
};

export const searchClipListAction = (data: any) => async (dispatch: any) => {
  dispatch(appClipActions.fetchClipRequest());

  try {
    const response = await Http.Post({
      path: `clips/search`,
      data,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    dispatch(appClipActions.fetchClipSuccess(result));
  } catch (error: any) {
    dispatch(appClipActions.fetchClipFailure(error.message));
  }
};
//GET ALL Clip
export const fetchClipAction = (clipId: number) => async (dispatch: any) => {
  dispatch(appClipActions.fetchClipSingleRequest());

  try {
    const response = await Http.Get({
      path: `clips/${clipId}`,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    dispatch(appClipActions.fetchClipSingleSuccess(result));
  } catch (error: any) {
    dispatch(appClipActions.fetchClipSingleFailure(error.message));
  }
};

//CREATE A Clip
export const createNewClipAction = (data: any) => async (dispatch: any) => {
  try {
    const response = await Http.Post({
      path: `clips`,
      data,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    toast.success('Created');
    return result;
  } catch (error: any) {
    toast.error(error.message);
    throw error;
  }
};

//DELETE A SLIDER
export const deleteClipItemAction =
  (clipId: number) => async (dispatch: any) => {
    try {
      const response = await Http.Delete({
        path: `clips/${clipId}`,
        useAuth: true,
      });
      const result = await response.json();

      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.success('Deleted');
      dispatch(appClipActions.deleteItemSuccess(clipId));
    } catch (error: any) {
      toast.error(error.message);
    }
  };

//UPDATE A SLIDER ITEM
export const updateClipItemAction =
  (clipId: number, data: any) => async (dispatch: any) => {
    const toastId = toast.loading('Updating...');
    try {
      const response = await Http.Put({
        path: `clips/${clipId}`,
        data,
        useAuth: true,
      });
      const result = await response.json();

      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.update(toastId, {
        render: 'Updated',
        type: 'success',
        isLoading: false,
        autoClose: 4000,
      });
      dispatch(appClipActions.updateItemSuccess(result));
    } catch (error: any) {
      toast.update(toastId, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 4000,
      });
    }
  };
