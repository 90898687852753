import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import errorExtractor from "../utils/errorExtractor";
import Http from './../helpers/http';
import { type Brand } from './../models/brand';

type BrandState = {
    brandList: {
        loading: boolean,
        data: Brand[] | null,
        error?: string
    }
};

const initialState: BrandState = {
    brandList: {
        loading: false,
        data: null
    }
}

const brandSlice = createSlice({
    name: "brand",
    initialState,
    reducers: {
        fetchBrandListRequest: (state) => {
            state.brandList.loading = true;
        },
        fetchBrandListSuccess: (state, action) => {
            state.brandList = {
                loading: false,
                data: action.payload
            }
        },
        fetchBrandListFailure: (state, action) => {
            state.brandList.loading = false;
            state.brandList.error = action.payload;
        },
        addNewBrandSuccess: (state, action) => {
            if (state.brandList.data) {
                state.brandList.data.unshift(action.payload)
            }
        },
        deleteBrandSuccess: (state, action) => {
            if (state.brandList.data) {
                state.brandList.data = state.brandList.data.filter(brand => brand.id !== action.payload)
            }
        },
        updateBrandSuccess: (state, action) => {
            if (state.brandList.data) {
                state.brandList.data = state.brandList.data.map(brand => brand.id === action.payload.id ? action.payload : brand);
            }
        }
    }
});

export default brandSlice.reducer;
export const brandActions = brandSlice.actions;


export const fetchBrandListAction = () => async (dispatch: any) => {
    dispatch(brandActions.fetchBrandListRequest());
    try {
        const response = await Http.Get({ path: `brands`, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        dispatch(brandActions.fetchBrandListSuccess(result.data));
    } catch (error: any) {
        dispatch(brandActions.fetchBrandListFailure(error.message));
    }
};

//ADD A NEW BRAND
export const addNewBrandAction = (data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Post({ path: `brands`, data, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        dispatch(brandActions.addNewBrandSuccess(result));
        toast.success("Added")
    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}

//DELETE A BRAND
export const deleteBrandAction = (brandId: number) => async (dispatch: any) => {
    try {
        const response = await Http.Delete({ path: `brands/${brandId}`, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        dispatch(brandActions.deleteBrandSuccess(brandId));
        toast.success("Deleted")
    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
};

//UPDATE BRAND
export const updateBrandAction = (brandId: number, data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Put({ path: `brands/${brandId}`, data, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        dispatch(brandActions.updateBrandSuccess(result));

        toast.success("Updated")
    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}
