import React, { useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout';
import BasicLayout from '../components/BasicLayout';
import ProtectedRoutes from '../components/ProtectedRoute';
import { useAppSelector } from '../hooks/redux';

//PAGES
const DashboardPage = React.lazy(
  () => import('./../pages/dashboard/DashboardPage')
);
const ProductPriceChangeList = React.lazy(
  () => import('./../pages/dashboard/ProductPriceChangeList')
);
const MediaPage = React.lazy(() => import('./../pages/MediaPage'));
const MenuManagePage = React.lazy(() => import('../pages/MenuManagePage'));
const NotFoundPage = React.lazy(() => import('../pages/NotFoundPage'));

//order pages
const OrderNewPage = React.lazy(() => import('./../pages/order/OrderNewPage'));
const OrderListPage = React.lazy(
  () => import('./../pages/order/OrderListPage')
);
const OrderEditPage = React.lazy(() => import('../pages/order/OrderEditPage'));
const OrderDetailsPage = React.lazy(
  () => import('../pages/order/OrderDetailsPage')
);
const PendingProductPage = React.lazy(
  () => import('../pages/order/PendingProductPage')
);
const SalesReportPage = React.lazy(
  () => import('../pages/order/SalesReportPage')
);
const SalesStaffPage = React.lazy(
  () => import('../pages/order/SalesStaffPage')
);
//bill pages
const BillDetailsPage = React.lazy(
  () => import('../pages/bill/BillDetailsPage')
);
const BillingListPage = React.lazy(
  () => import('../pages/bill/BillingListPage')
);
//payment pages
const PaymentDetailsPage = React.lazy(
  () => import('../pages/payments/PaymentDetailsPage')
);
const PaymentListPage = React.lazy(
  () => import('../pages/payments/PaymentListPage')
);

//pre order pages
const PreOrderListPage = React.lazy(
  () => import('../pages/preOrder/PreOrderListPage')
);
const PreOrderDetailsPage = React.lazy(
  () => import('../pages/preOrder/PreOrderDetailsPage')
);

//product pages
const DeviceListPage = React.lazy(
  () => import('../pages/usedDevice/DeviceListPage')
);
const DeviceEditPage = React.lazy(
  () => import('../pages/usedDevice/DeviceEditPage')
);
const DeviceNewPage = React.lazy(
  () => import('../pages/usedDevice/DeviceNewPage')
);
const DeviceOrderListPage = React.lazy(
  () => import('../pages/usedDevice/DeviceOrderListPage')
);
const DeviceOrderPage = React.lazy(
  () => import('../pages/usedDevice/DeviceOrderPage')
);

//product pages
const ProductListPage = React.lazy(
  () => import('../pages/product/ProductListPage')
);
const NewProductPage = React.lazy(
  () => import('../pages/product/NewProductPage')
);
const EditProductPage = React.lazy(
  () => import('../pages/product/EditProductPage')
);
const PriceContributorList = React.lazy(
  () => import('../pages/dashboard/PriceContributorList')
);
const PriceContributor = React.lazy(
  () => import('../pages/dashboard/PriceContributor')
);
const ActiveLog = React.lazy(() => import('../pages/dashboard/ActiveLog'));
const ActiveLogDetails = React.lazy(
  () => import('../pages/dashboard/ActiveLogDetails')
);
const StockoutProductList = React.lazy(
  () => import('../pages/dashboard/StockoutProductList')
);

//category pages
const AddCategoryPage = React.lazy(
  () => import('../pages/category/AddCategoryPage')
);
const EditCategoryPage = React.lazy(
  () => import('../pages/category/EditCategoryPage')
);
const ManageCategoryPage = React.lazy(
  () => import('../pages/category/ManageCategoryPage')
);

//attribute pages
const AttributeManage = React.lazy(() => import('./../pages/attribute'));
const AttributeEditPage = React.lazy(
  () => import('../pages/attribute/AttributeEditPage')
);

//delivery manage pages
const DeliveryTypePage = React.lazy(
  () => import('../pages/delivery/DeliveryTypePage')
);
const DeliveryOptionPage = React.lazy(
  () => import('../pages/delivery/DeliveryOptionPage')
);
const DeliveryCityAreaPage = React.lazy(
  () => import('../pages/delivery/DeliveryCityAreaPage')
);
const CityUnderDelivery = React.lazy(
  () => import('../pages/delivery/CityUnderDelivery')
);

//brand manage
const BrandAddPage = React.lazy(() => import('../pages/brand/BrandAddPage'));
const BrandListPage = React.lazy(() => import('../pages/brand/BrandListPage'));
const BrandEditPage = React.lazy(() => import('../pages/brand/BrandEditPage'));

//app content manage pages
const MenuContentPage = React.lazy(
  () => import('../pages/appContent/MenuContent')
);
const SliderManagePage = React.lazy(
  () => import('../pages/appContent/SliderManage')
);
const ClipContentPage = React.lazy(
  () => import('../pages/appContent/ClipListPage')
);
const ClipCreatePage = React.lazy(
  () => import('../pages/appContent/ClipCreatePage')
);
const ClipEditPage = React.lazy(
  () => import('../pages/appContent/ClipEditPage')
);
const ContentListPage = React.lazy(
  () => import('../pages/appContent/ContentListPage')
);
const ContentNewPage = React.lazy(
  () => import('../pages/appContent/ContentNewPage')
);
const ContentEditPage = React.lazy(
  () => import('../pages/appContent/ContentEditPage')
);
const NotificationPage = React.lazy(
  () => import('../pages/appContent/NotificationPage')
);
const NotificationCreatePage = React.lazy(
  () => import('../pages/appContent/NotificationCreatePage')
);
const NotificationEditPage = React.lazy(
  () => import('../pages/appContent/NotificationEditPage')
);

//home content manage pages
const HomeSliderManagePage = React.lazy(
  () => import('../pages/homeContent/HomeSliderManagePage')
);
const HomeLayoutManagePage = React.lazy(
  () => import('../pages/homeContent/HomeLayoutManagePage')
);
const HomeContentEditPage = React.lazy(
  () => import('../pages/homeContent/HomeContentEditPage')
);
const AddNewHomeContentPage = React.lazy(
  () => import('../pages/homeContent/AddNewHomeContentPage')
);
const HomeSliderEditPage = React.lazy(
  () => import('../pages/homeContent/HomeSliderEditPage')
);
const HomeSeo = React.lazy(() => import('../pages/homeContent/HomeSeo'));

const AddBanner = React.lazy(() => import('../pages/homeContent/AddBanner'));
//report pages
const CustomerPurchasePage = React.lazy(
  () => import('../pages/report/CustomerPurchasePage')
);
const ProductSalesQtyPage = React.lazy(
  () => import('../pages/report/ProductSalesQtyPage')
);
const SalesPage = React.lazy(() => import('../pages/report/SalesPage'));

//reward pages
const CustomerRewardListPage = React.lazy(
  () => import('../pages/reward/CustomerRewardListPage')
);
const CustomerRewardPage = React.lazy(
  () => import('../pages/reward/CustomerRewardPage')
);
const SettingRewardPage = React.lazy(
  () => import('../pages/reward/SettingRewardPage')
);

//career page
const MainPage = React.lazy(() => import('../pages/career/MainPage'));
const JobListPage = React.lazy(() => import('../pages/career/JobListPage'));
const AddNewJob = React.lazy(() => import('../pages/career/AddNewJob'));
const EditJobPage = React.lazy(() => import('../pages/career/JobEditPage'));
const AllApplicant = React.lazy(() => import('../pages/career/AllApplicant'));
const ApplicantListPage = React.lazy(
  () => import('../pages/career/ApplicantListPage')
);
const ApplicantDetails = React.lazy(
  () => import('../pages/career/ApplicantDetails')
);

//setting page
const SiteSettingPage = React.lazy(
  () => import('../pages/setting/SiteSettingPage')
);
const ShopAddressPage = React.lazy(
  () => import('../pages/setting/ShopAddressPage')
);
const EmiPage = React.lazy(() => import('../pages/setting/EmiPage'));
const FooterSettingPage = React.lazy(
  () => import('../pages/setting/FooterSettingPage')
);
const Redirection = React.lazy(() => import('../pages/setting/Redirection'));
const ChatPage = React.lazy(() => import('../pages/setting/ChatPage'));

//custom page manage
const CustomPageListPage = React.lazy(
  () => import('../pages/customPage/CustomPageListPage')
);
const CustomPageEditPage = React.lazy(
  () => import('../pages/customPage/CustomPageEditPage')
);
const CustomPageNewPage = React.lazy(
  () => import('../pages/customPage/CustomPageNewPage')
);
//faq page manage
const FaqCategoryList = React.lazy(
  () => import('../pages/faq/CategoryListPage')
);
const CategoryDetailsPage = React.lazy(
  () => import('../pages/faq/CategoryDetailsPage')
);

//campaign page
const CouponListPage = React.lazy(
  () => import('../pages/campaign/CouponListPage')
);
const EditCouponCode = React.lazy(
  () => import('../pages/campaign/EditCouponCode')
);
const AddCouponCode = React.lazy(
  () => import('../pages/campaign/AddCouponCode')
);
const CampOfferManagePage = React.lazy(
  () => import('../pages/campaign/CampOfferManagePage')
);
const PressCoverage = React.lazy(
  () => import('../pages/campaign/PressCoverage')
);
const CreateOfferPage = React.lazy(
  () => import('../pages/campaign/CreateOfferPage')
);
const EditOfferPage = React.lazy(
  () => import('../pages/campaign/EditOfferPage')
);
const PromotionalPopup = React.lazy(
  () => import('../pages/campaign/PromotionalPopup')
);
const PointList = React.lazy(() => import('../pages/campaign/PointList'));

//search terms
const AddNewSearchTerm = React.lazy(
  () => import('../pages/searchTerms/AddNewSearchTermPage')
);
const EditSearchTermPage = React.lazy(
  () => import('../pages/searchTerms/EditSearchTermPage')
);
const SearchTermsListPage = React.lazy(
  () => import('../pages/searchTerms/SearchTermsListPage')
);

//authentication
const LoginPage = React.lazy(() => import('../pages/auth/LoginPage'));
const RegistrationPage = React.lazy(
  () => import('../pages/auth/RegistrationPage')
);

//admin
const AdminListPage = React.lazy(() => import('../pages/admin/AdminListPage'));
const EditAdminPage = React.lazy(() => import('../pages/admin/EditAdminPage'));

//specifications
const SpecListPage = React.lazy(() => import('../pages/specs/SpecListPage'));
//customers
const CustomerListPage = React.lazy(
  () => import('../pages/customer/CustomerListPage')
);
const CustomerDetailsPage = React.lazy(
  () => import('../pages/customer/CustomerDetailsPage')
);

const AppNavigator = () => {
  const me = useAppSelector((state) => state.auth.me);
  const orderManagerType = useMemo(() => {
    let name: string = '';
    if (me.data && me.data.permissions.length) {
      const manage =
        me.data.permissions[0].permissions.includes('order_manage');
      const web =
        me.data.permissions[0].permissions.includes('order_manage_web');
      const coverwala =
        me.data.permissions[0].permissions.includes('order_coverwala');
      const manual = me.data.permissions[0].permissions.includes(
        'order_manage_manual'
      );
      if (manage) {
        name = 'order_manage';
      }
      if (web) {
        name = 'order_manage_web';
      }
      if (manual) {
        name = 'order_manage_manual';
      }
      if (coverwala) {
        name = 'order_coverwala';
      }
      return name;
    } else {
      return '';
    }
  }, [me.data]);

  // console.log(orderManagerType);
  return (
    <Routes>
      <Route path='/' element={<BasicLayout />}>
        <Route path='login' element={<LoginPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Route>
      <Route path='/' element={<Layout />}>
        <Route path='/'>
          <Route
            index
            element={
              <ProtectedRoutes path='dashboard'>
                <DashboardPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='/price_change_list'
            element={
              <ProtectedRoutes path='dashboard'>
                <ProductPriceChangeList />
              </ProtectedRoutes>
            }
          />
          <Route
            path='price_contributor'
            element={
              <ProtectedRoutes path='product_manage'>
                <PriceContributorList />
              </ProtectedRoutes>
            }
          />
          <Route
            path='price_contributor/:contributorId'
            element={
              <ProtectedRoutes path='product_manage'>
                <PriceContributor />
              </ProtectedRoutes>
            }
          />
          <Route
            path='active_list'
            element={
              <ProtectedRoutes path='product_manage'>
                <ActiveLog />
              </ProtectedRoutes>
            }
          />
          <Route
            path='active_list/:logID'
            element={
              <ProtectedRoutes path='product_manage'>
                <ActiveLogDetails />
              </ProtectedRoutes>
            }
          />
          <Route
            path='stockout_product_list'
            element={
              <ProtectedRoutes path='product_manage'>
                <StockoutProductList />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route
          path='media'
          element={
            <ProtectedRoutes path='media'>
              <MediaPage />
            </ProtectedRoutes>
          }
        />
        <Route path='bill'>
          <Route
            index
            element={
              <ProtectedRoutes path='bill_manage'>
                <BillingListPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path=':billId'
            element={
              <ProtectedRoutes path='bill_manage'>
                <BillDetailsPage />
              </ProtectedRoutes>
            }
          />
        </Route>
        <Route path='account'>
          <Route
            index
            element={
              <ProtectedRoutes path='accounts_manage'>
                <PaymentListPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path=':accountId'
            element={
              <ProtectedRoutes path='accounts_manage'>
                <PaymentDetailsPage />
              </ProtectedRoutes>
            }
          />
        </Route>
        <Route path='order'>
          <Route
            path='new'
            element={
              <ProtectedRoutes path={orderManagerType}>
                <OrderNewPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path=':orderId'
            element={
              <ProtectedRoutes path={orderManagerType}>
                <OrderDetailsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='edit/:orderId'
            element={
              <ProtectedRoutes path={orderManagerType}>
                <OrderEditPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='pre'
            element={
              <ProtectedRoutes path={orderManagerType}>
                <PreOrderListPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='pre/:preOrderId'
            element={
              <ProtectedRoutes path={orderManagerType}>
                <PreOrderDetailsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='pending_product'
            element={
              <ProtectedRoutes path={orderManagerType}>
                <PendingProductPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='Sales_staff'
            element={
              <ProtectedRoutes path={orderManagerType}>
                <SalesStaffPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='sales_staff/:staffId'
            element={
              <ProtectedRoutes path={orderManagerType}>
                <SalesReportPage />
              </ProtectedRoutes>
            }
          />
          <Route
            index
            element={
              <ProtectedRoutes path={orderManagerType}>
                <OrderListPage />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route path='category'>
          <Route
            path='new'
            element={
              <ProtectedRoutes path='category_manage'>
                <AddCategoryPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path=':categoryId'
            element={
              <ProtectedRoutes path='category_manage'>
                <EditCategoryPage />
              </ProtectedRoutes>
            }
          />
          <Route
            index
            element={
              <ProtectedRoutes path='category_manage'>
                <ManageCategoryPage />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route path='attributes'>
          <Route
            path=':attrId'
            element={
              <ProtectedRoutes path='product_manage'>
                <AttributeEditPage />
              </ProtectedRoutes>
            }
          />
          <Route
            index
            element={
              <ProtectedRoutes path='product_manage'>
                <AttributeManage />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route path='product'>
          <Route
            path='new'
            element={
              <ProtectedRoutes path='product_manage'>
                <NewProductPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path=':productId'
            element={
              <ProtectedRoutes path='product_manage'>
                <EditProductPage />
              </ProtectedRoutes>
            }
          />
          <Route
            index
            element={
              <ProtectedRoutes path='product_manage'>
                <ProductListPage />
              </ProtectedRoutes>
            }
          />
        </Route>
        <Route path='used-device'>
          <Route path='order'>
            <Route
              index
              element={
                <ProtectedRoutes path='product_manage'>
                  <DeviceOrderListPage />
                </ProtectedRoutes>
              }
            />
            <Route
              path=':orderId'
              element={
                <ProtectedRoutes path='product_manage'>
                  <DeviceOrderPage />
                </ProtectedRoutes>
              }
            />
          </Route>
          <Route
            path='new'
            element={
              <ProtectedRoutes path='product_manage'>
                <DeviceNewPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path=':productId'
            element={
              <ProtectedRoutes path='product_manage'>
                <DeviceEditPage />
              </ProtectedRoutes>
            }
          />
          <Route
            index
            element={
              <ProtectedRoutes path='product_manage'>
                <DeviceListPage />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route path='customer'>
          <Route
            path=':customerId'
            element={
              <ProtectedRoutes path='customer'>
                <CustomerDetailsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            index
            element={
              <ProtectedRoutes path='customer'>
                <CustomerListPage />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route path='terms'>
          <Route
            index
            element={
              <ProtectedRoutes path='search_terms'>
                <SearchTermsListPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='new'
            element={
              <ProtectedRoutes path='search_terms'>
                <AddNewSearchTerm />
              </ProtectedRoutes>
            }
          />
          <Route
            path='edit/:termId'
            element={
              <ProtectedRoutes path='search_terms'>
                <EditSearchTermPage />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route
          path='menu'
          element={
            <ProtectedRoutes path='menu_manage'>
              <MenuManagePage />
            </ProtectedRoutes>
          }
        />

        <Route path='brand'>
          <Route
            path='new'
            element={
              <ProtectedRoutes path='brand_manage'>
                <BrandAddPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='edit/:brandId'
            element={
              <ProtectedRoutes path='brand_manage'>
                <BrandEditPage />
              </ProtectedRoutes>
            }
          />
          <Route
            index
            element={
              <ProtectedRoutes path='brand_manage'>
                <BrandListPage />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route path='delivery'>
          <Route
            path='type'
            element={
              <ProtectedRoutes path='delivery_manage'>
                <DeliveryTypePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='option'
            element={
              <ProtectedRoutes path='delivery_manage'>
                <DeliveryOptionPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='city-area'
            element={
              <ProtectedRoutes path='delivery_manage'>
                <DeliveryCityAreaPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='city-under-delivery'
            element={
              <ProtectedRoutes path='delivery_manage'>
                <CityUnderDelivery />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route path='custom-page'>
          <Route
            index
            element={
              <ProtectedRoutes path='custom_page'>
                <CustomPageListPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='new'
            element={
              <ProtectedRoutes path='custom_page'>
                <CustomPageNewPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path=':pageId'
            element={
              <ProtectedRoutes path='custom_page'>
                <CustomPageEditPage />
              </ProtectedRoutes>
            }
          />
        </Route>
        <Route path='faq'>
          <Route
            index
            element={
              <ProtectedRoutes path='faq_manage'>
                <FaqCategoryList />
              </ProtectedRoutes>
            }
          />
          <Route
            path=':faqId'
            element={
              <ProtectedRoutes path='faq_manage'>
                <CategoryDetailsPage />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route path='home-content'>
          <Route
            path='slider'
            element={
              <ProtectedRoutes path='home_content_manage'>
                <HomeSliderManagePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='slider/:sliderId'
            element={
              <ProtectedRoutes path='home_content_manage'>
                <HomeSliderEditPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='layout'
            element={
              <ProtectedRoutes path='home_content_manage'>
                <HomeLayoutManagePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='layout/new'
            element={
              <ProtectedRoutes path='home_content_manage'>
                <AddNewHomeContentPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='layout/:contentId'
            element={
              <ProtectedRoutes path='home_content_manage'>
                <HomeContentEditPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='seo'
            element={
              <ProtectedRoutes path='home_content_manage'>
                <HomeSeo />
              </ProtectedRoutes>
            }
          />
        </Route>
        <Route path='app-content'>
          <Route
            path='menu'
            element={
              <ProtectedRoutes path='app_manage'>
                <MenuContentPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='layout'
            element={
              <ProtectedRoutes path='app_manage'>
                <ContentListPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='layout/new'
            element={
              <ProtectedRoutes path='app_manage'>
                <ContentNewPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='layout/:contentId'
            element={
              <ProtectedRoutes path='home_content_manage'>
                <ContentEditPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='slider'
            element={
              <ProtectedRoutes path='app_manage'>
                <SliderManagePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='clip'
            element={
              <ProtectedRoutes path='app_manage'>
                <ClipContentPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='clip/new'
            element={
              <ProtectedRoutes path='app_manage'>
                <ClipCreatePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='clip/:contentId'
            element={
              <ProtectedRoutes path='app_manage'>
                <ClipEditPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='notification'
            element={
              <ProtectedRoutes path='app_manage'>
                <NotificationPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='notification/new'
            element={
              <ProtectedRoutes path='app_manage'>
                <NotificationCreatePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='notification/:notificationId'
            element={
              <ProtectedRoutes path='app_manage'>
                <NotificationEditPage />
              </ProtectedRoutes>
            }
          />
        </Route>
        <Route
          path='content/banner'
          element={
            <ProtectedRoutes path='home_content_manage'>
              <AddBanner />
            </ProtectedRoutes>
          }
        />

        <Route path='report'>
          <Route
            path='customers'
            element={
              <ProtectedRoutes path='report'>
                <CustomerPurchasePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='products'
            element={
              <ProtectedRoutes path='report'>
                <ProductSalesQtyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='sales'
            element={
              <ProtectedRoutes path='report'>
                <SalesPage />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route path='reward'>
          <Route
            path='customer'
            element={
              <ProtectedRoutes path='customer'>
                <CustomerRewardListPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='customer/:customerId'
            element={
              <ProtectedRoutes path='customer'>
                <CustomerRewardPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='setting'
            element={
              <ProtectedRoutes path='customer'>
                <SettingRewardPage />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route path='campaign'>
          <Route path='coupon'>
            <Route
              index
              element={
                <ProtectedRoutes path='campaign'>
                  <CouponListPage />
                </ProtectedRoutes>
              }
            />
            <Route
              path='new'
              element={
                <ProtectedRoutes path='campaign'>
                  <AddCouponCode />
                </ProtectedRoutes>
              }
            />
            <Route
              path=':couponId'
              element={
                <ProtectedRoutes path='campaign'>
                  <EditCouponCode />
                </ProtectedRoutes>
              }
            />
          </Route>
          <Route path='offer'>
            <Route
              path='new'
              element={
                <ProtectedRoutes path='campaign'>
                  <CreateOfferPage />
                </ProtectedRoutes>
              }
            />
            <Route
              path='edit/:offerId'
              element={
                <ProtectedRoutes path='campaign'>
                  <EditOfferPage />
                </ProtectedRoutes>
              }
            />
            <Route
              index
              element={
                <ProtectedRoutes path='campaign'>
                  <CampOfferManagePage />
                </ProtectedRoutes>
              }
            />
          </Route>
          <Route
            path='press_coverage'
            element={
              <ProtectedRoutes path='campaign'>
                <PressCoverage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='popup'
            element={
              <ProtectedRoutes path='campaign'>
                <PromotionalPopup />
              </ProtectedRoutes>
            }
          />
          <Route
            path='chat'
            element={
              <ProtectedRoutes path='campaign'>
                <ChatPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='point'
            element={
              <ProtectedRoutes path='campaign'>
                <PointList />
              </ProtectedRoutes>
            }
          />
        </Route>
        <Route path='admin-manage'>
          <Route
            path='new'
            element={
              <ProtectedRoutes path='admin_manage'>
                <RegistrationPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='list'
            element={
              <ProtectedRoutes path='admin_manage'>
                <AdminListPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='list/:staffId'
            element={
              <ProtectedRoutes path='admin_manage'>
                <EditAdminPage />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route path='career'>
          <Route
            index
            element={
              <ProtectedRoutes path='career'>
                <MainPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='list'
            element={
              <ProtectedRoutes path='career'>
                <JobListPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='new'
            element={
              <ProtectedRoutes path='career'>
                <AddNewJob />
              </ProtectedRoutes>
            }
          />
          <Route
            path='list/:jobId'
            element={
              <ProtectedRoutes path='career'>
                <EditJobPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='all-applicant'
            element={
              <ProtectedRoutes path='career'>
                <AllApplicant />
              </ProtectedRoutes>
            }
          />
          <Route
            path='all-applicant/:applicantId'
            element={
              <ProtectedRoutes path='career'>
                <ApplicantListPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='all-applicant/:applicantId/:applicant'
            element={
              <ProtectedRoutes path='career'>
                <ApplicantDetails />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route path='setting'>
          <Route
            index
            element={
              <ProtectedRoutes path='setting'>
                <SiteSettingPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='shop-address'
            element={
              <ProtectedRoutes path='setting'>
                <ShopAddressPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='emi'
            element={
              <ProtectedRoutes path='setting'>
                <EmiPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='footer'
            element={
              <ProtectedRoutes path='setting'>
                <FooterSettingPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='redirection'
            element={
              <ProtectedRoutes path='setting'>
                <Redirection />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route path='specifications'>
          <Route
            index
            element={
              <ProtectedRoutes path='specifications'>
                <SpecListPage />
              </ProtectedRoutes>
            }
          />
        </Route>
        <Route path='*' element={<NotFoundPage />} />
      </Route>
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppNavigator;
