import BarLoader from "react-spinners/BarLoader";

const Loader = () => {
    return (
        <div className='w-full h-[90vh] center-inner'>
            <BarLoader color={"#F17E23"} loading={true} />
        </div>
    )
}

export default Loader