import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Http from './../helpers/http';
import errorExtractor from './../utils/errorExtractor';
import type { HomeContent, bannerContent, Slider } from '../models/homeContent';
import { type Media } from '../models/media';

type StaticMedia = {
  url: string;
  image: Media;
  position: 1 | 2;
};

type HomeContentState = {
  addContentPending: boolean;
  contentList: {
    loading: boolean;
    data: HomeContent[];
    error?: string;
  };
  contentDetails: {
    loading: boolean;
    data: HomeContent | null;
    error?: string | null;
  };
  sliderList: {
    loading: boolean;
    data: Slider[];
    error?: string;
  };
  sliderDetails: {
    loading: boolean;
    data: Slider | null;
    error?: string;
  };
  staticImages: {
    loading: boolean;
    data: StaticMedia[];
    error?: string;
  };
  bannerDetails: {
    loading: boolean;
    data: bannerContent | null;
    error?: string | null;
  };
};

const initialState: HomeContentState = {
  addContentPending: false,
  contentList: {
    loading: false,
    data: [],
  },
  contentDetails: {
    loading: false,
    data: null,
  },
  sliderList: {
    loading: false,
    data: [],
  },
  sliderDetails: {
    loading: false,
    data: null,
  },
  staticImages: {
    loading: false,
    data: [],
  },
  bannerDetails: {
    loading: false,
    data: null,
  },
};

const homeContentSlice = createSlice({
  name: 'homeContent',
  initialState,
  reducers: {
    fetchHomeContentPending: (state) => {
      state.contentList.loading = true;
    },
    fetchHomeContentSuccess: (state, action) => {
      state.contentList = {
        loading: false,
        data: action.payload,
        error: undefined,
      };
    },
    fetchHomeContentFailure: (state, action) => {
      state.contentList.loading = false;
      state.contentList.error = action.payload;
    },
    setAddContentPending: (state, action) => {
      state.addContentPending = action.payload;
    },
    deleteHomeContentSuccess: (state, action) => {
      state.contentList.data = state.contentList.data.filter(
        (content) => content.id !== action.payload
      );
    },
    fetchHomeContentDetailsRequest: (state) => {
      state.contentDetails = {
        loading: true,
        data: null,
        error: null,
      };
    },
    fetchHomeContentDetailsSuccess: (state, action) => {
      state.contentDetails = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    fetchHomeContentDetailsFailure: (state, action) => {
      state.contentDetails = {
        ...state.contentDetails,
        loading: false,
        error: action.payload,
      };
    },
    updateHomeContentSuccess: (state, action) => {
      state.contentList.data = state.contentList.data.map((content) =>
        content.id !== action.payload.id ? content : action.payload
      );
    },

    //Add Banner
    fetchBannerContentPending: (state) => {
      state.bannerDetails.loading = true;
    },
    fetchBannerContentSuccess: (state, action) => {
      state.bannerDetails = {
        loading: false,
        data: action.payload,
        error: undefined,
      };
    },
    fetchBannerContentFailure: (state, action) => {
      state.bannerDetails.loading = false;
      state.bannerDetails.error = action.payload;
    },
    //SLIDER
    fetchSliderListRequest: (state) => {
      state.sliderList.loading = true;
    },
    fetchSliderListSuccess: (state, action) => {
      state.sliderList = {
        loading: false,
        data: action.payload,
      };
    },
    fetchSliderListFailure: (state, action) => {
      state.sliderList.loading = false;
      state.sliderList.error = action.payload;
    },
    //get a slider details
    fetchSliderDetailsRequest: (state) => {
      state.sliderDetails.loading = true;
    },
    fetchSliderDetailsSuccess: (state, action) => {
      state.sliderDetails = {
        loading: false,
        data: action.payload,
      };
    },
    fetchSliderDetailsFailure: (state, action) => {
      state.sliderDetails.loading = false;
      state.sliderDetails.error = action.payload;
    },
    //slider create
    createNewSliderSuccess: (state, action) => {
      if (state.sliderList.data.length) {
        state.sliderList.data.push(action.payload);
      }
    },
    //delete a slider
    deleteSliderItemSuccess: (state, action) => {
      state.sliderList.data = state.sliderList.data.filter(
        (slider) => slider.id !== action.payload
      );
    },
    //update slider
    updateSliderItemSuccess: (state, action) => {
      state.sliderList.data = state.sliderList.data.map((slider) =>
        slider.id === action.payload.id ? action.payload : slider
      );
    },
    //STATIC IMAGES//
    fetchStaticImagesRequest: (state) => {
      state.staticImages.loading = true;
    },
    fetchStaticImagesSuccess: (state, action) => {
      state.staticImages = {
        loading: false,
        data: action.payload,
      };
    },
    fetchStaticImagesFailure: (state, action) => {
      state.staticImages.loading = false;
      state.staticImages.error = action.payload;
    },
  },
});

export default homeContentSlice.reducer;
export const homeContentActions = homeContentSlice.actions;

//OTHER ACTIONS

//fetch home content list
export const fetchHomeContents = () => async (dispatch: any) => {
  dispatch(homeContentActions.fetchHomeContentPending());
  try {
    const response = await Http.Get({
      path: `homepage-contents`,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      throw new Error('Failed');
    }

    dispatch(homeContentActions.fetchHomeContentSuccess(result.data));
  } catch (error: any) {
    dispatch(homeContentActions.fetchHomeContentFailure(error.message));
  }
};

//add new home content
export const addHomeContentAction = (data: any) => async (dispatch: any) => {
  dispatch(homeContentActions.setAddContentPending(true));
  const toastId = toast.loading('Adding...');
  try {
    const response = await Http.Post({
      path: `homepage-contents`,
      data,
      useAuth: true,
    });
    const result = await response.json();
    if (!response.ok) {
      const err = errorExtractor(result.message);
      throw new Error(err[0]);
    }
    dispatch(homeContentActions.setAddContentPending(false));
    toast.update(toastId, {
      render: 'Added',
      type: 'success',
      isLoading: false,
      autoClose: 4000,
    });
    dispatch(fetchHomeContents());
  } catch (error: any) {
    dispatch(homeContentActions.setAddContentPending(false));
    toast.update(toastId, {
      render: error.message,
      type: 'error',
      isLoading: false,
      autoClose: 4000,
    });
    throw error;
  }
};

//delete a home content
export const deleteHomeContentAction =
  (contentId: number) => async (dispatch: any) => {
    const toastId = toast.loading('Deleting...');
    try {
      const response = await Http.Delete({
        path: `homepage-contents/${contentId}`,
        useAuth: true,
      });

      if (!response.ok) {
        const result = await response.json();
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.update(toastId, {
        render: 'Deleted',
        type: 'success',
        isLoading: false,
        autoClose: 4000,
      });
      dispatch(homeContentActions.deleteHomeContentSuccess(contentId));
    } catch (error: any) {
      toast.update(toastId, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 4000,
      });
    }
  };

//get a content details
export const fetchHomeContentDetailsAction =
  (contentId: number) => async (dispatch: any) => {
    dispatch(homeContentActions.fetchHomeContentDetailsRequest());
    try {
      const response = await Http.Get({
        path: `homepage-contents/${contentId}`,
        useAuth: true,
      });

      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      dispatch(homeContentActions.fetchHomeContentDetailsSuccess(result));
    } catch (error: any) {
      dispatch(
        homeContentActions.fetchHomeContentDetailsFailure(error.message)
      );
    }
  };

//update home content
export const updateHomeContentAction =
  (contentId: number, data: any) => async (dispatch: any) => {
    const toastId = toast.loading('Updating...');
    try {
      const response = await Http.Put({
        path: `homepage-contents/${contentId}`,
        data,
        useAuth: true,
      });
      const result = await response.json();

      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.update(toastId, {
        render: 'Updated',
        type: 'success',
        isLoading: false,
        autoClose: 4000,
      });
      dispatch(homeContentActions.updateHomeContentSuccess(result));
    } catch (error: any) {
      toast.update(toastId, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 4000,
      });
      throw error;
    }
  };

//GET A SLIDER DETAILS
export const fetchSliderDetailsAction =
  (sliderId: number) => async (dispatch: any) => {
    dispatch(homeContentActions.fetchSliderDetailsRequest());
    try {
      const response = await Http.Get({
        path: `homepage-sliders/${sliderId}`,
        useAuth: true,
      });
      const result = await response.json();

      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      dispatch(homeContentActions.fetchSliderDetailsSuccess(result));
    } catch (error: any) {
      dispatch(homeContentActions.fetchSliderDetailsFailure(error.message));
    }
  };

//GET ALL HOMEPAGE SLIDERS
export const fetchSliderListAction = () => async (dispatch: any) => {
  dispatch(homeContentActions.fetchSliderListRequest());

  try {
    const response = await Http.Get({
      path: `homepage-sliders`,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    dispatch(homeContentActions.fetchSliderListSuccess(result));
  } catch (error: any) {
    dispatch(homeContentActions.fetchSliderListFailure(error.message));
  }
};

//CREATE A HOMEPAGE SLIDER
export const createNewSliderAction = (data: any) => async (dispatch: any) => {
  try {
    const response = await Http.Post({
      path: `homepage-sliders`,
      data,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    toast.success('Created');
    dispatch(homeContentActions.createNewSliderSuccess(result));
  } catch (error: any) {
    toast.error(error.message);
    throw error;
  }
};

//DELETE A SLIDER
export const deleteSliderItemAction =
  (sliderId: number) => async (dispatch: any) => {
    try {
      const response = await Http.Delete({
        path: `homepage-sliders/${sliderId}`,
        useAuth: true,
      });
      const result = await response.json();

      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.success('Deleted');
      dispatch(homeContentActions.deleteSliderItemSuccess(sliderId));
    } catch (error: any) {
      toast.error(error.message);
    }
  };

//UPDATE A SLIDER ITEM
export const updateSliderItemAction =
  (sliderId: number, data: any) => async (dispatch: any) => {
    const toastId = toast.loading('Updating...');
    try {
      const response = await Http.Put({
        path: `homepage-sliders/${sliderId}`,
        data,
        useAuth: true,
      });
      const result = await response.json();

      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.update(toastId, {
        render: 'Updated',
        type: 'success',
        isLoading: false,
        autoClose: 4000,
      });
      dispatch(homeContentActions.updateSliderItemSuccess(result));
    } catch (error: any) {
      toast.update(toastId, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 4000,
      });
    }
  };

//STATIC IMAGES //
export const fetchStaticImagesAction = () => async (dispatch: any) => {
  dispatch(homeContentActions.fetchStaticImagesRequest());

  try {
    const response = await Http.Get({
      path: `homepage-sliders/statics`,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    dispatch(homeContentActions.fetchStaticImagesSuccess(result.content));
  } catch (error: any) {
    dispatch(homeContentActions.fetchStaticImagesFailure(error.message));
  }
};
export const updateStaticImagesAction =
  (data: any) => async (dispatch: any) => {
    try {
      const response = await Http.Post({
        path: `homepage-sliders/statics`,
        data,
        useAuth: true,
      });
      const result = await response.json();

      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.success('Updated');
      dispatch(homeContentActions.fetchStaticImagesSuccess(result.content));
    } catch (error: any) {
      toast.error(error.message);
    }
  };

//fetch home content list
export const fetchBannerContents = () => async (dispatch: any) => {
  dispatch(homeContentActions.fetchBannerContentPending());
  try {
    const response = await Http.Get({
      path: `banner`,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      throw new Error('Failed');
    }
    dispatch(homeContentActions.fetchBannerContentSuccess(result));
  } catch (error: any) {
    dispatch(homeContentActions.fetchBannerContentFailure(error.message));
  }
};
export const updateContentAction = (data: any) => async (dispatch: any) => {
  dispatch(homeContentActions.fetchBannerContentPending());
  try {
    const response = await Http.Put({
      path: `banner/${data.id}`,
      data,
      useAuth: true,
    });
    const result = await response.json();

    if (!response.ok) {
      throw new Error('Failed');
    }
    dispatch(homeContentActions.fetchBannerContentSuccess(result));
  } catch (error: any) {
    dispatch(homeContentActions.fetchBannerContentFailure(error.message));
  }
};
