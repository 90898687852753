import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Http from './../helpers/http';

import type Pagination from '../models/pagination';

export type Media = {
    id: number,
    name: string,
    file_name: string,
    mime_type: string,
    size: number,
    created_at: string,
    updated_at: string
};


interface MediaState {
    mediaList: {
        loading: boolean,
        data: Media[],
        error?: null | string,
        meta?: Pagination
    },
    mediaDelete: {
        loading: boolean
    }
}
const initialState: MediaState = {
    mediaList: {
        loading: false,
        data: []
    },
    mediaDelete: {
        loading: false
    }
};

export const mediaSlice = createSlice({
    name: "media",
    initialState,
    reducers: {
        fetchMediaRequest: (state) => {
            state.mediaList.loading = true;
        },
        fetchMediaSuccess: (state, action) => {
            state.mediaList = {
                loading: false,
                data: action.payload.meta.current_page > 1 ? [...state.mediaList.data, ...action.payload.data] : action.payload.data,
                error: null,
                meta: action.payload.meta
            }
        },
        fetchMediaFailure: (state, action) => {
            state.mediaList.loading = false;
            state.mediaList.error = action.payload;
        },
        //MEDIA DELETE REDUCERS
        deleteMediaRequest: (state) => {
            state.mediaDelete.loading = true;
        },
        deleteMediaSuccess: (state, action) => {
            state.mediaDelete.loading = false;
            state.mediaList.data = state.mediaList.data.filter((media) => media.id !== action.payload);
        },
        deleteMediaFailure: (state) => {
            state.mediaDelete.loading = false;
        }
    }
});

export const mediaActions = mediaSlice.actions;
export default mediaSlice.reducer;


//REDUX ACTIONS
export const fetchMediaAction = (pageNo: number = 1) => async (dispatch: any) => {
    dispatch(mediaActions.fetchMediaRequest());
    try {
        const response = await Http.Get({ path: `media?page=${pageNo}`, useAuth: true });

        if (!response.ok) {
            throw new Error("Fail to fetch media data");
        }
        const result = await response.json();
        dispatch(mediaActions.fetchMediaSuccess({ data: result.data, meta: result.meta }));

    } catch (error: any) {
        dispatch(mediaActions.fetchMediaFailure(error.message));
    }
}
export const deleteMediaAction = (mediaId: number) => async (dispatch: any) => {
    dispatch(mediaActions.deleteMediaRequest());
    try {
        const response = await Http.Delete({ path: `media/${mediaId}`, useAuth: true });
        if (!response.ok) {
            throw new Error("Fail to remove media");
        }
        dispatch(mediaActions.deleteMediaSuccess(mediaId));
        toast.success("Deleted");
    } catch (error: any) {
        dispatch(mediaActions.deleteMediaFailure());
        toast.error(error.message);
    }
}

export const searchMediaAction = (searchText: string, pageNo: number = 1) => async (dispatch: any) => {
    dispatch(mediaActions.fetchMediaRequest());
    try {
        const response = await Http.Post({
            path: `search/media?page=${pageNo}`, data: {
                search_term: searchText
            }, useAuth: true
        });

        if (!response.ok) {
            throw new Error("Fail to fetch media data");
        }
        const result = await response.json();
        dispatch(mediaActions.fetchMediaSuccess({ data: result.data, meta: result.meta }));


    } catch (error: any) {
        dispatch(mediaActions.fetchMediaFailure(error.message));

    }
}