import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Http from '../helpers/http';
import {
  CategoryType,
  CategoryQuestionType,
  QuestionCategoryType,
} from '../models/faq';
import errorExtractor from '../utils/errorExtractor';
type faqState = {
  categoryList: {
    loading: boolean;
    list: CategoryType[];
    error?: string;
  };
  categoryDetails: {
    loading: boolean;
    data?: CategoryQuestionType;
    error?: string;
  };
  questionList: {
    loading: boolean;
    list: QuestionCategoryType[];
    error?: string;
  };
};

const initialState: faqState = {
  categoryList: {
    loading: false,
    list: [],
  },
  categoryDetails: {
    loading: false,
  },
  questionList: {
    loading: false,
    list: [],
  },
};

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    //category list
    fetchFaqCategoryListRequest: (state) => {
      state.categoryList.loading = true;
    },
    fetchFaqCategoryListSuccess: (state, action) => {
      state.categoryList = {
        loading: false,
        list: action.payload,
      };
    },
    fetchFaqCategoryListFailure: (state, action) => {
      state.categoryList.loading = false;
      state.categoryList.error = action.payload;
    },
    //question list
    fetchFaqQuestionListRequest: (state) => {
      state.categoryDetails.loading = true;
    },
    fetchFaqQuestionListSuccess: (state, action) => {
      state.categoryDetails = {
        loading: false,
        data: action.payload,
      };
    },
    fetchFaqQuestionListFailure: (state, action) => {
      state.categoryDetails.loading = false;
      state.categoryDetails.error = action.payload;
    },
  },
});

export default faqSlice.reducer;
export const faqActions = faqSlice.actions;
//category list
export const fetchFaqCategoryList = () => async (dispatch: any) => {
  dispatch(faqActions.fetchFaqCategoryListRequest());
  try {
    const res = await Http.Get({
      path: `faq/cat`,
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed');
    }

    dispatch(faqActions.fetchFaqCategoryListSuccess(result));
  } catch (error: any) {
    dispatch(faqActions.fetchFaqCategoryListFailure(error.message));
  }
};
export const faqCategoryCreateAction = (data: any) => async (dispatch: any) => {
  try {
    const response = await Http.Post({
      path: `faq/cat`,
      data,
      useAuth: true,
    });
    if (!response.ok) {
      const result = await response.json();
      if (typeof result.message === 'string') {
        throw new Error(result.message);
      } else {
        throw new Error(errorExtractor(result.message)[0]);
      }
    }
    toast.success('FAQ category created success');
    dispatch(fetchFaqCategoryList());
  } catch (error: any) {
    toast.error(error.message);
    dispatch(faqActions.fetchFaqCategoryListFailure(error.message));
  }
};
export const faqCategoryUpdateAction =
  ({ id, data }: { id: number; data: any }) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Put({
        path: `faq/cat/${id}`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.success('FAQ category updated success');
      dispatch(fetchFaqCategoryList());
    } catch (error: any) {
      toast.error(error.message);
      dispatch(faqActions.fetchFaqCategoryListFailure(error.message));
    }
  };
export const faqCategoryDeleteAction =
  (id: number) => async (dispatch: any) => {
    try {
      const response = await Http.Delete({
        path: `faq/cat/${id}`,
        useAuth: true,
      });
      if (!response.ok) {
        const result = await response.json();
        if (typeof result.message === 'string') {
          throw new Error(result.message);
        } else {
          throw new Error(errorExtractor(result.message)[0]);
        }
      }
      toast.success('FAQ category deleted success');
      dispatch(fetchFaqCategoryList());
    } catch (error: any) {
      toast.error(error.message);
      dispatch(faqActions.fetchFaqCategoryListFailure(error.message));
    }
  };
//category list
export const fetchFaqQuestionList =
  (id = 1) =>
  async (dispatch: any) => {
    dispatch(faqActions.fetchFaqQuestionListRequest());
    try {
      const res = await Http.Get({
        path: `faq/cat/${id}/questions`,
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed');
      }

      dispatch(faqActions.fetchFaqQuestionListSuccess(result));
    } catch (error: any) {
      dispatch(faqActions.fetchFaqQuestionListFailure(error.message));
    }
  };
