import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Http from '../helpers/http';
type Department = { id: number; name: string };
type DepartmentState = {
  departmentList: {
    loading: boolean;
    data: Department[];
    error?: string;
  };
};

const initialState: DepartmentState = {
  departmentList: {
    loading: false,
    data: [],
  },
};

const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    fetchDepartmentListRequest: (state) => {
      state.departmentList.loading = true;
    },
    fetchDepartmentListSuccess: (state, action) => {
      state.departmentList = {
        loading: false,
        data: action.payload,
      };
    },
    fetchDepartmentListFailure: (state, action) => {
      state.departmentList.loading = false;
      state.departmentList.error = action.payload;
    },
  },
});

export default departmentSlice.reducer;
export const DepartmentActions = departmentSlice.actions;

export const fetchDepartmentAction = () => async (dispatch: any) => {
  dispatch(DepartmentActions.fetchDepartmentListRequest());
  try {
    const res = await Http.Get({
      path: 'staffs/dept',
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed');
    }

    dispatch(DepartmentActions.fetchDepartmentListSuccess(result));
  } catch (error: any) {
    dispatch(DepartmentActions.fetchDepartmentListFailure(error.message));
  }
};
export const updateDepartmentAction = (data: any, DepartmentId: number) => async (dispatch: any) => {
  try {
    const res = await Http.Put({
      path: `staffs/dept/${DepartmentId}`,
      data,
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed');
    }

    dispatch(DepartmentActions.fetchDepartmentListFailure(result));
    toast.success('Updated');
  } catch (error: any) {
    toast.error(error.message);
  }
};
