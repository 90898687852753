/**
 * Returns string to json value.
 * @param {string} value
 * @returns {JSON} value
 */
function getJson<T>(value: string): T | null {
  try {
    return JSON.parse(value) as T;
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null;
  }
}

export default getJson;
